<template>
    <div
        v-on:click="tileClick"
        class="w-full md:w-auto aspect-w-1 aspect-h-1 md:aspect-none cursor-pointer"
        :class="{
            backgroundClass,
            'cursor-pointer': active,
            'cursor-not-allowed': !active,
        }"
    >
        <div class="w-full h-full md:relative md:w-60 md:h-60 shadow-lg">
            <div
                class="absolute top-0 right-0 bottom-0 left-0 bg-cover bg-no-repeat"
                v-bind:style="tileBackgroundStyle"
            ></div>

            <div
                class="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center text-sm font-semibold bg-opacity-90"
                v-bind:class="'text-white' + ' ' + overlayFullColorClass"
            />

            <div
                v-if="this.$slots.icon"
                style="bottom: 63px"
                class="absolute top-0 right-0 left-0 flex justify-center items-center"
            >
                <div class="w-20 h-20" v-bind:class="'text-white'">
                    <slot name="icon" />
                </div>
            </div>

            <div
                v-if="this.$slots.label"
                style="height: 63px"
                class="absolute right-0 bottom-0 left-0 flex justify-center items-center text-sm font-semibold bg-opacity-90 p-2"
                v-bind:class="colorClass + ' ' + overlayShortColorClass"
            >
                <div class="line-clamp-3 text-center">
                    <slot name="label" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tile",
    props: [
        "image",
        "externalImage",
        "label",
        "color",
        "background",
        "notAllowed",
        "overlayFull",
        "overlayColor",
        "openFlipHtml",
        "openImage",
        "open3QSDN",
        "openText",
        "popupTitle",
        "popupSubtitle",
    ],
    overlayColor: {
        type: String,
        default: "beigene-blue",
    },
    overlayFull: {
        type: Boolean,
        default: false,
    },
    computed: {
        active: function () {
            return !this.notAllowed;
        },

        tileBackgroundStyle: function () {
            if (this.image) {
                return { backgroundImage: `url(/img/${this.image})` };
            }

            if (this.externalImage) {
                return { backgroundImage: `url(${this.externalImage})` };
            }

            return {};
        },

        colorClass: function () {
            if (this.color) {
                return [`text-${this.color}`];
            }
            return ["text-white"];
        },

        backgroundClass: function () {
            if (this.background) {
                return [`bg-${this.background}`];
            }
            return [];
        },

        overlayFullColorClass: function () {
            if (this.overlayColor && this.overlayFull) {
                return [`bg-${this.overlayColor}`];
            }
            return [];
        },

        overlayShortColorClass: function () {
            if (this.overlayColor && !this.overlayFull) {
                return [`bg-${this.overlayColor}`];
            }
            return [];
        },
    },
    methods: {
        tileClick: function () {
            if (this.openFlipHtml) {
                this.$root.$emit(
                    "openPopup",
                    "fliphtml5",
                    this.openFlipHtml,
                    this.popupTitle,
                    this.popupSubtitle
                );
            }
            if (this.openImage) {
                this.$root.$emit(
                    "openPopup",
                    "image",
                    this.openImage,
                    this.popupTitle,
                    this.popupSubtitle
                );
            }
            if (this.open3QSDN) {
                this.$root.$emit(
                    "openPopup",
                    "3qsdn",
                    this.open3QSDN,
                    this.popupTitle,
                    this.popupSubtitle
                );
            }
            if (this.openText) {
                this.$root.$emit(
                    "openPopup",
                    "text",
                    this.openText,
                    this.popupTitle,
                    this.popupSubtitle
                );
            }
        },
    },
};
</script>
