<template>
    <div
        class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-none md:flex md:flex-row md:flex-wrap max-w-1032"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: "TileContainer"
};
</script>
