import { BaseApiService } from "./apiBase";

export default class CancelApiService extends BaseApiService {
    constructor() {
        super("removeuser");
    }

    async cancelParticipation() {
        const config = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        };
        return this.fetch(config);
    }
}
