import store from "../store/store";

const loginEnabled =
    process.env.VUE_APP_LOGIN_ENABLED === "true" ? true : false;

export default async function checkAuth(next) {
    let authenticated = true;

    const accessToken = store.state.accessToken;
    const accessTokenExpiresAt = new Date(store.state.accessTokenExpiresAt);

    if (!loginEnabled) {
        authenticated = false;
    }

    if (accessToken == null || accessToken == "") {
        authenticated = false;
    }

    if (
        accessTokenExpiresAt == null &&
        accessTokenExpiresAt == "" &&
        accessTokenExpiresAt < new Date()
    ) {
        authenticated = false;
    }

    if (!authenticated) {
        next({ path: "/login" });
    } else {
        next();
    }
}
