<template>
    <table class="w-full table-auto">
        <thead>
            <tr>
                <th class="bg-beigene-blue text-white px-2 py-1">
                    {{ $t("agenda.table.header.time") }}
                </th>
                <th class="bg-beigene-blue text-white px-2 py-1">
                    {{ $t("agenda.table.header.topic") }}
                </th>
                <th class="bg-beigene-blue text-white px-2 py-1">
                    {{ $t("agenda.table.header.speaker") }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="(agendaItem, index) in agendaItems"
                class="border-b border-beigene-blue"
            >
                <td
                    class="px-2 py-1"
                    v-html="$t('agenda.table.data.' + agendaItem + '.time')"
                ></td>
                <td
                    class="px-2 py-1"
                    v-html="$t('agenda.table.data.' + agendaItem + '.topic')"
                ></td>
                <td
                    class="px-2 py-1"
                    v-html="$t('agenda.table.data.' + agendaItem + '.speaker')"
                ></td>
            </tr>
        </tbody>
    </table>
    <!--
    <div class="grid grid-cols-6 lg:grid-cols-12 w-full gap-4">
        <div class="col-span-6 lg:col-span-4 whitespace-pre-line">
            <span
                class="inline font-bold"
                v-html="$t('agenda.content-left.title')"
            ></span>
            <ul class="list-disc list-outside ml-5 mt-5">
                <li>{{ $t("agenda.content-left.content-a") }}</li>
                <li>{{ $t("agenda.content-left.content-b") }}</li>
                <li>{{ $t("agenda.content-left.content-c") }}</li>
                <li>{{ $t("agenda.content-left.content-d") }}</li>
                <li>{{ $t("agenda.content-left.content-e") }}</li>
            </ul>
        </div>
        <div class="col-span-6 lg:col-span-4 whitespace-pre-line ">
            <span class="inline font-bold">{{
                $t("agenda.content-right.title")
            }}</span>
            <div class="flex flex-row gap-4 mt-5">
                <div class="flex flex-col shrink-0 w-20">
                    <img
                        src="/speaker/Brown.png"
                        style="outline: 4px solid #ffffff; outline-offset: -3px; border-radius: 50px;"
                    />
                </div>
                <div class="flex flex-col whitespace-pre-line">
                    <div class="font-bold">
                        {{ $t("agenda.content-right.speaker-a.name") }}
                    </div>
                    <div>
                        {{ $t("agenda.content-right.speaker-a.desc") }}
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-4 mt-3">
                <div class="flex flex-col shrink-0 w-20">
                    <img
                        src="/speaker/Buske.png"
                        style="outline: 4px solid #ffffff; outline-offset: -3px; border-radius: 50px;"
                    />
                </div>
                <div class="whitespace-pre-line">
                    <div class="font-bold">
                        {{ $t("agenda.content-right.speaker-b.name") }}
                    </div>
                    <div>
                        {{ $t("agenda.content-right.speaker-b.desc") }}
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-4 mt-3">
                <div class="flex flex-col shrink-0 w-20">
                    <img
                        src="/speaker/Levin.png"
                        style="outline: 4px solid #ffffff; outline-offset: -3px; border-radius: 50px;"
                    />
                </div>
                <div class="whitespace-pre-line">
                    <div class="font-bold">
                        {{ $t("agenda.content-right.speaker-c.name") }}
                    </div>
                    <div>
                        {{ $t("agenda.content-right.speaker-c.desc") }}
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-4 mt-3">
                <div class="flex flex-col shrink-0 w-20">
                    <img
                        src="/speaker/Schuh.png"
                        style="outline: 4px solid #ffffff; outline-offset: -3px; border-radius: 50px;"
                    />
                </div>
                <div class="whitespace-pre-line">
                    <div class="font-bold">
                        {{ $t("agenda.content-right.speaker-e.name") }}
                    </div>
                    <div>
                        {{ $t("agenda.content-right.speaker-e.desc") }}
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-4 mt-3">
                <div class="flex flex-col shrink-0 w-20">
                    <img
                        src="/speaker/Zinzani.png"
                        style="outline: 4px solid #ffffff; outline-offset: -3px; border-radius: 50px;"
                    />
                </div>
                <div class="whitespace-pre-line">
                    <div class="font-bold">
                        {{ $t("agenda.content-right.speaker-f.name") }}
                    </div>
                    <div>
                        {{ $t("agenda.content-right.speaker-f.desc") }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-6 lg:col-span-4 whitespace-pre-line">
            <span class="inline font-bold">{{
                $t("agenda.content-right-beigene.title")
            }}</span>
            <div class="flex flex-row gap-4 mt-5">
                <div class="flex flex-col shrink-0 w-20">
                    <img
                        src="/speaker/WuX_HS.png"
                        style="outline: 4px solid #ffffff; outline-offset: -3px; border-radius: 50px;"
                    />
                </div>
                <div class="whitespace-pre-line">
                    <div class="font-bold">
                        {{ $t("agenda.content-right-beigene.speaker-d.name") }}
                    </div>
                    <div>
                        {{ $t("agenda.content-right-beigene.speaker-d.desc") }}
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-4 mt-3">
                <div class="flex flex-col shrink-0 w-20">
                    <img
                        src="/speaker/Weber.png"
                        style="outline: 4px solid #ffffff; outline-offset: -3px; border-radius: 50px;"
                    />
                </div>
                <div class="flex flex-col whitespace-pre-line">
                    <div class="font-bold">
                        {{ $t("agenda.content-right-beigene.speaker-a.name") }}
                    </div>
                    <div>
                        {{ $t("agenda.content-right-beigene.speaker-a.desc") }}
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-4 mt-3">
                <div class="flex flex-col shrink-0 w-20">
                    <img
                        src="/speaker/Langer.png"
                        style="outline: 4px solid #ffffff; outline-offset: -3px; border-radius: 50px;"
                    />
                </div>
                <div class="whitespace-pre-line">
                    <div class="font-bold">
                        {{ $t("agenda.content-right-beigene.speaker-b.name") }}
                    </div>
                    <div>
                        {{ $t("agenda.content-right-beigene.speaker-b.desc") }}
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-4 mt-3">
                <div class="flex flex-col shrink-0 w-20">
                    <img
                        src="/speaker/Mobasher.png"
                        style="outline: 4px solid #ffffff; outline-offset: -3px; border-radius: 50px;"
                    />
                </div>
                <div class="whitespace-pre-line">
                    <div class="font-bold">
                        {{ $t("agenda.content-right-beigene.speaker-c.name") }}
                    </div>
                    <div>
                        {{ $t("agenda.content-right-beigene.speaker-c.desc") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    -->
</template>
<style scoped>
.shrink-0 {
    flex-shrink: 0;
}
</style>
<script>
export default {
    name: "AgendaContent",
    components: {},
    methods: {},
    data() {
        return {
            parentMessage: "Parent",
            agendaItems: Array.from({ length: 6 }, (_, index) => {
                return index + 1;
            })
        };
    }
};
</script>
