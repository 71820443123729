import AuthApiService from "./authApi";
import BeigeneApiService from "./beigeneApi";
import ProfileApiService from "./profileApi";
import CancelApiService from "./cancelApi";
import RegistrationLimitsApiService from "./registrationLimitsApiService";

export const $api = {
    auth: new AuthApiService(),
    beigene: new BeigeneApiService(),
    profile: new ProfileApiService(),
    remove: new CancelApiService(),
    registration: {
        // TODO: registration should be implemented here
        limits: new RegistrationLimitsApiService(),
    },
};
