import { ReadOnlyApiService } from "./apiBase";

export default class RegistrationLimitsApiService extends ReadOnlyApiService {
    constructor() {
        super("registration/limits");
    }

    async beigene() {
        return await this.get("beigene", {});
    }
}
