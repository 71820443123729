<template>
    <div
        class="relative sm:max-w-xl ml-auto h-0"
        v-show="$route.path.startsWith('/content')"
    >
        <nav class="border-3 border-white rounded">
            <button
                class="w-14 h-14 relative focus:outline-none bg-beigene-blue rounded"
                @click="toogleNav"
            >
                <div
                    class="block w-5 absolute left-6 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                    <span
                        class="block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out"
                        :class="{
                            'rotate-45':
                                this.$store.state.toogleShowHideNavigation ===
                                true,
                            ' -translate-y-1.5':
                                this.$store.state.toogleShowHideNavigation ===
                                false
                        }"
                    ></span>
                    <span
                        class="block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out"
                        :class="{
                            'opacity-0':
                                this.$store.state.toogleShowHideNavigation ===
                                true
                        }"
                    ></span>
                    <span
                        class="block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out"
                        :class="{
                            '-rotate-45':
                                this.$store.state.toogleShowHideNavigation ===
                                true,
                            ' translate-y-1.5':
                                this.$store.state.toogleShowHideNavigation ===
                                false
                        }"
                    ></span>
                </div>
            </button>
        </nav>
    </div>
</template>

<script>
export default {
    name: "HelperHamburger",
    methods: {
        toogleNav() {
            function showNavigation() {
                var element = document.getElementById("NavigationMain");
                element.classList.remove("nav-close");
                element.classList.add("nav-open");
            }

            function hideNavigation() {
                var element = document.getElementById("NavigationMain");
                element.classList.add("nav-close");
                setTimeout(function() {
                    element.classList.remove("nav-open");
                }, 300);
            }

            if (this.$store.state.toogleShowHideNavigation === true) {
                this.$store.commit("updateNavigationStatus", false);
                hideNavigation();
            } else {
                this.$store.commit("updateNavigationStatus", true);
                showNavigation();
            }
        }
    },
    computed: {
        toogleActive: {
            get() {
                return this.$store.state.toogleShowHideNavigation;
            },
            set(value) {
                this.$store.commit("updateNavigationStatus", value);
            }
        }
    }
};
</script>

<style></style>
