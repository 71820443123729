<template>
    <div
        class="w-full m-4 mb-10 lg:mb-2 md:m-12 md:mt-11 md:mb-0 relative bg-white shadow-lg p-4 max-w-1032"
    >
        <div class="flex items-center space-x-3.5 mb-4 pb-4 border-b">
            <div
                class="flex-none w-12 h-12 rounded-full text-white bg-beigene-blue flex items-center justify-center"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    />
                </svg>
            </div>
            <div>
                <p class="text-2xl font-semibold">
                    {{ $t("agenda.title") }}
                </p>
            </div>
        </div>
        <div class="mb-4 lg:mb-8">
            <p class="text-lg font-normal mr-10 xl:mr-20">
                {{ $t("agenda.intro") }}
            </p>
        </div>
        <AgendaContent />
        <!--
        <div
            class="hidden lg:grid grid-cols-12 w-full py-2 pr-10 border-b-2 border-b-beigene-grey text-lg font-semibold"
        >
            <div class="col-span-2">{{ $t("agenda.time") }}</div>
            <div class="col-span-4">
                {{ $t("agenda.presenter") }}
            </div>
            <div class="col-span-6">
                {{ $t("agenda.titleKey") }}
            </div>
        </div>
        <div
            class="grid grid-cols-12 w-full py-2 pr-10 border-b-2 border-b-beigene-grey text-lg font-semibold lg:hidden"
        >
            <div class="col-span-12 mt-4">
                {{ $t("agenda.time") }}, {{ $t("agenda.presenter") }},
                {{ $t("agenda.titleKey") }}
            </div>
        </div>
        <div
            v-for="(agendaItem, index) in agenda"
            class="grid grid-cols-12 w-full py-2 pr-10 border-b-2 border-b-beigene-grey text-lg font-normal hover:bg-gray-100"
        >
            <div class="col-span-12 lg:col-span-2 font-semibold lg:font-normal">
                {{ agendaItem.time }}
            </div>
            <div class="col-span-12 lg:col-span-4">
                {{ agendaItem.presenter }}
                <span
                    v-if="agendaItem.presenterTitle != ''"
                    class="block text-md font-normal italic"
                    >{{ agendaItem.presenterTitle }}</span
                >
            </div>
            <div class="col-span-12 lg:col-span-6 mt-2 lg:mt-0">
                {{ agendaItem.title }}
                <span
                    v-if="agendaItem.titleAdditional != ''"
                    class="block text-md font-normal"
                    >{{ agendaItem.titleAdditional }}</span
                >
            </div>
        </div>
        -->
    </div>
</template>
<script>
import AgendaContent from "./AgendaContent";

export default {
    name: "Agenda",
    components: { AgendaContent },
    data() {
        return {
            // time, presenter, title
            agenda: [
                {
                    time: "19.00 – 19.30 h",
                    presenter: "TBC",
                    presenterTitle: "TBC",
                    title: "Arrival, Registration, Reception & Welcome"
                },
                {
                    time: "19.30 – 19.40 h",
                    presenter: "TBC",
                    presenterTitle: "TBC",
                    title: "Introduction to BeiGene Company"
                },
                {
                    time: "19.40 – 19.55 h",
                    presenter: "Mehrdad Mobasher",
                    presenterTitle:
                        "BeiGene, Chief Medical Officer, Hematology",
                    title:
                        "Introduction to Heme Clinical Development Program & Future Outlook"
                },
                {
                    time: "19.55 – 20.10 h",
                    presenter: "Keynote Speaker (TBC)",
                    presenterTitle: "TBC",
                    title: "Status and Future of CLL Treatment"
                },
                {
                    time: "20.10 – 20.35 h",
                    presenter: "Expert Panel (TBC)",
                    presenterTitle: "TBC",
                    title: "Q&A and open panel discussion",
                    titleAdditional: "Dinner & Networking"
                }
            ]
        };
    },
    methods: {}
};
</script>
