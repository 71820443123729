import { ModelApiService } from "./apiBase";

export default class BeigeneApiService extends ModelApiService {
    constructor() {
        super("beigene");
    }

    /*
    async getUser() {
        return await this.get("user");
    }
    */
}
