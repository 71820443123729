import { ActionApiService } from "./apiBase";

export default class AuthApiService extends ActionApiService {
    constructor() {
        super("auth");
    }

    async revoke() {
        return await this.post("revoke", {});
    }
}
