<template>
    <footer class="flex w-full px-9 py-2 text-white text-l">
        <ul class="list-none flex flex-col md:flex-row md:justify-center">
            <li class="p-2 md:p-3">
                {{ $t("basics.copyrightNote") }}
            </li>
            <li class="p-2 md:p-3">
                <a
                    href="./legal-notice"
                    class="hover:text-beigene-light-blue"
                    >{{ $t("basics.imprint") }}</a
                >
            </li>
            <li class="p-2 md:p-3">
                <a
                    href="https://www.beigene.com/privacy-policy/"
                    class="hover:text-beigene-light-blue"
                    target="_blank"
                    >{{ $t("basics.privacyPolicy") }}</a
                >
            </li>
        </ul>
    </footer>
</template>

<script>
export default {
    name: "MainFooter"
};
</script>

<style></style>
