<template>
    <div class="flex flex-col h-screen">
        <!-- Page deactivation -->
        <!-- <MainHeader />

        <main
            class="flex flex-col flex-grow flex-shrink-0 text-beigene-grey container-height sm:container-height-md md:items-center md:justify-center"
        >
            <router-view id="app" />
        </main>

        <MainFooter />

        <PopupOverlay /> -->
    </div>
</template>

<style>
header a span {
    letter-spacing: 0.08rem;
}

.layerBG {
    margin-top: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-bottom: 2rem;
    padding-bottom: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 25px;
}

@media screen and (max-width: 1280px) {
    .layerBG {
        margin-left: 0rem;
        margin-right: 0rem;
        padding-left: 0rem;
        padding-right: 0rem;
    }
}
</style>

<script>
import MainHeader from "./components/MainHeader";
import MainFooter from "./components/MainFooter";
import PopupOverlay from "./components/PopupOverlay";

export default {
    components: {
        MainHeader,
        MainFooter,
        PopupOverlay
    }
};
</script>
