<template>
    <div class="flex max-w-screen-xl w-full layerBG">
        <div class="w-full my-4 mx-4 2xl:mx-0 text-center overflow-y-auto">
            <h1
                class="text-beigene-blue font-bold mb-8"
                v-html="$t('messages.welcome')"
            ></h1>

            <div
                v-if="successMessage"
                class="col-span-24 text-center text-green-700 font-semibold relative"
            >
                <p
                    class="absolute w-full -mt-6 text-green-700 font-semibold text-center"
                    v-html="
                        $t('messages.PasswordResetDone', {
                            linkLogin:
                                '<a target=\'_self\' href=\'/login\' class=\'underline cursor-pointer text-green-700\'>' +
                                $t('messages.PasswordResetDoneLinkText') +
                                '</a>'
                        })
                    "
                ></p>
            </div>
            <div v-else>
                <div class="relative">
                    <p class="mb-6 text-2xl">
                        {{ $t("messages.PasswordReset") }}
                    </p>
                    <ValidationObserver v-slot="{ handleSubmit }">
                        <form
                            @submit.prevent="handleSubmit(resetPassword)"
                            id="userInputs"
                        >
                            <div class="max-w-screen-sm mx-auto">
                                <div
                                    class="grid grid-cols-12 gap-x-4 gap-y-8 mb-10"
                                >
                                    <div
                                        class="md:col-start-1 md:h-11 xl:h-auto col-span-12 sm:col-span-3 text-left sm:text-right"
                                    >
                                        <label
                                            for="password"
                                            class="h-full inline-block flex flex-col justify-center text-2xl"
                                            >{{ $t("basics.password") }}</label
                                        >
                                    </div>

                                    <div
                                        class="md:h-11 xl:h-auto col-span-12 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                                    >
                                        <validation-provider
                                            name="password"
                                            rules="required|min:3"
                                            v-slot="{ errors }"
                                            mode="aggressive"
                                        >
                                            <t-input
                                                type="password"
                                                id="password"
                                                name="password"
                                                class="px-3 py-2 rounded-full h-10 w-full"
                                                v-model="password"
                                            />

                                            <div
                                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                                            >
                                                {{ errors[0] }}
                                            </div>
                                        </validation-provider>
                                    </div>
                                    <div
                                        class="md:col-start-1 md:h-11 xl:h-auto col-span-12 sm:col-span-3 text-left sm:text-right"
                                    >
                                        <label
                                            for="retype_password"
                                            class="h-full inline-block flex flex-col justify-center text-2xl"
                                            >{{
                                                $t("basics.passwordRetype")
                                            }}</label
                                        >
                                    </div>

                                    <div
                                        class="md:h-11 xl:h-auto col-span-12 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                                    >
                                        <validation-provider
                                            name="retype_password"
                                            rules="required|confirmed:password"
                                            v-slot="{ errors }"
                                            mode="aggressive"
                                        >
                                            <t-input
                                                type="password"
                                                id="retype_password"
                                                name="retype_password"
                                                class="px-3 py-2 rounded-full h-10 w-full"
                                                v-model="retypepassword"
                                            />
                                            <div
                                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                                            >
                                                {{
                                                    errors[0]
                                                        ? errors[0].replace(
                                                              "retype_password",
                                                              "password re-type"
                                                          )
                                                        : ""
                                                }}
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>

                                <div class="col-span-24">
                                    <t-button
                                        v-if="
                                            errors != '' ||
                                                password == '' ||
                                                retypepassword == ''
                                        "
                                        class="mb-8 inline rounded-full w-48 h-12 text-xl font-semibold bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed"
                                        >{{
                                            $t("basics.resetPasswordButton")
                                        }}</t-button
                                    >

                                    <t-button
                                        v-else-if="APIcallInProgress === true"
                                        class="mb-8 inline rounded-full w-48 h-12 text-xl font-semibold bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed"
                                        >{{ $t("basics.loading") }}</t-button
                                    >

                                    <t-button
                                        v-else-if="sendButtonDisabled === true"
                                        class="mb-8 inline rounded-full w-48 h-12 text-xl font-semibold bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed"
                                        >{{ $t("basics.loading") }}</t-button
                                    >

                                    <t-button
                                        v-else
                                        type="submit"
                                        class="mb-8 inline rounded-full w-48 h-12 text-xl font-semibold"
                                        >{{
                                            $t("basics.resetPasswordButton")
                                        }}</t-button
                                    >
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                    <div>
                        <div
                            v-if="errorMessage"
                            class="col-span-24 text-center text-red-700 font-semibold relative"
                        >
                            <p
                                class="absolute w-full -mt-6 text-red-700 font-semibold text-center"
                            >
                                {{ errorMessage }}
                            </p>
                        </div>
                        <p
                            class="mt-8 text-2xl"
                            v-html="
                                $t('basics.issues', {
                                    link:
                                        '<a target=\'_blank\' title=\'support@infill.com\' href=\'mailto:support@infill.com?subject=' +
                                        encodeURIComponent(
                                            $t('basics.issuesMailSubject')
                                        ) +
                                        '\' class=\'text-beigene-blue\'>' +
                                        $t('basics.issuesLabel') +
                                        '</a>'
                                })
                            "
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "../store/store";
import router from "../router";
import axios from "axios";
import qs from "qs";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

const url = process.env.VUE_APP_API_URL;

export default {
    data() {
        return {
            errorMessage: "",
            errors: "",
            password: "",
            retypepassword: "",
            successMessage: "",
            APIcallInProgress: false,
            sendButtonDisabled: false
        };
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    methods: {
        resetPassword() {
            //get token from url param
            const resetToken = this.$route.params.token;

            if (this.errors == "" && this.$route.params.token.length == 36) {
                // get data from form inputs
                const userInputForm = document.getElementById("userInputs");
                const passwordFromUser = userInputForm.querySelector(
                    "input[name=password]"
                );
                const passwordRetypeFromUser = userInputForm.querySelector(
                    "input[name=retype_password]"
                );

                const axiosData = qs.stringify({
                    token: resetToken,
                    password: passwordFromUser.value,
                    passwordConfirm: passwordRetypeFromUser.value
                });

                this.APIcallInProgress = true;
                // put request
                axios
                    .put(url + "api/v1/resetpassword", axiosData, {
                        withCredentials: false
                    })
                    .then(() => {
                        this.APIcallInProgress = false;
                        this.sendButtonDisabled = true;
                        this.successMessage = this.$i18n.t(
                            "messages.PasswordResetDone"
                        );
                    })
                    .catch(error => {
                        this.APIcallInProgress = false;
                        this.errorMessage = this.$i18n.t(
                            "messagesError.somethingWrong"
                        );

                        if (
                            typeof error === "undefined" ||
                            typeof error.response === "undefined" ||
                            typeof error.response.data === "undefined" ||
                            typeof error.response.data.code === "undefined"
                        ) {
                            return;
                        }

                        const code = error.response.data.code;

                        switch (code) {
                            case "reset_password_token_expired":
                                setTimeout(() => {
                                    router.push("/forgotpassword");
                                }, 5000);
                                this.errorMessage = this.$i18n.t(
                                    "messagesError.PasswordTokenExpired"
                                );
                                break;
                            default:
                                break;
                        }
                    });
            } else {
                this.errorMessage = this.$i18n.t(
                    "messagesError.somethingWrong"
                );
            }
        }
    },
    computed: {}
};
</script>
