<template>
    <div
        class="flex flex-col w-full m-4 mb-0 lg:m-12 lg:mt-11 lg:mb-0 relative"
    >
        <Home2D />
    </div>
</template>
<script>
import store from "../../store/store";
import NavigationMain from "../../components/NavigationMain";
import NavigationSub from "../../components/NavigationSub";
import Home2D from "./Home2D";

export default {
    components: {
        NavigationMain,
        NavigationSub,
        Home2D
    }
};
</script>
