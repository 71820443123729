<template>
    <a
        :href="active ? link : 'javascript:'"
        :target="active ? '_blank' : '_self'"
        class="flex w-full py-1.5 px-4 rounded-xl items-center space-x-2 text-md hover:text-white "
        :class="{
            'hover:bg-beigene-light-blue': active,
            'hover:bg-gray-400 cursor-not-allowed': !active
        }"
        onclick="clicktracker.click('menu - breakoutgroup');"
        ><div class="flex w-6">
            <slot><IconEmpty /></slot>
        </div>
        <div class="font-semibold">{{ label }}&nbsp;</div>
    </a>
</template>

<script>
import IconEmpty from "./icons/Empty";

export default {
    name: "NavigationMainItemExternalLink",
    props: ["label", "link", "eventControl"],
    components: {
        IconEmpty
    },
    data: function() {
        return {
            active: true,
            eventControlled:
                this.eventControl &&
                this.eventControl.get &&
                this.eventControl.status
        };
    },
    methods: {
        initEventController: function() {
            this.$root.$on(
                this.eventControl.status,
                this.eventControlledHandler
            );
            this.$root.$emit(this.eventControl.get);
        },
        destroyEventController: function() {
            this.$root.$off(
                this.eventControl.status,
                this.eventControlledHandler
            );
        },
        eventControlledHandler: function(status) {
            this.active = status;
        }
    },
    created() {
        if (this.eventControlled) {
            this.initEventController();
        }
    },
    beforeDestroy() {
        if (this.eventControlled) {
            this.destroyEventController();
        }
    }
};
</script>
