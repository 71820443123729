<template>
    <div class="flex flex-row w-full h-full relative">
        <NavigationMain
            id="NavigationMain"
            class="flex flex-col flex-shrink-0 w-69 absolute xl:relative container-height-mobile-nav xl:nav-open
                        leading-10 sm:leading-loose container-height sm:container-height-md lg:container-height-lg
                        z-30 lg:flex"
        />
        <router-view id="content" class="z-0" />
    </div>
</template>

<script>
import NavigationMain from "../../components/NavigationMain";

export default {
    name: "ContentLayout",
    components: {
        NavigationMain
    },
    data: function() {
        return {
            beigene: null,
            profilePromise: null
        };
    },
    created() {
        this.initUserData();
    },
    async mounted() {
        this.processUserData();
    },
    methods: {
        async initUserData() {
            this.profilePromise = this.$api.profile.getProfile();
        },
        async processUserData() {
            const [profile] = await Promise.all([this.profilePromise]);
        }
    }
};
</script>
