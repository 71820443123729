import Vue from "vue";
import Vuex from "vuex";
import VueCryptojs from "vue-cryptojs";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import i18n from "./i18n";
import axios from "axios";
import VueTailwind from "vue-tailwind";
import "./assets/tailwind.css";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import "@/plugins/mixins";

import {
    TRadio,
    TInput,
    TButton,
    TChexbox,
    TSelect,
    TTextarea
} from "vue-tailwind/dist/components";

Vue.config.productionTip = false;

// TODO remove here and all API calls
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// tailwind components
const settings = {
    "t-button": {
        component: TButton,
        props: {
            fixedClasses:
                "block transition duration-100 ease-in-out focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
            classes:
                "text-white bg-beigene-blue shadow-sm rounded hover:bg-beigene-light-blue",
            variants: {
                secondary:
                    "text-gray-800 bg-white border border-gray-300 shadow-sm hover:text-gray-600",
                error:
                    "text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600",
                success:
                    "text-white bg-green-500 border border-transparent rounded shadow-sm hover:bg-green-600",
                link: "text-blue-500 underline hover:text-blue-600",
                disabled:
                    "text-white shadow-sm rounded bg-gray-400 cursor-not-allowed"
            }
        }
    },
    "t-input": {
        component: TInput,
        props: {
            fixedClasses:
                "transition duration-100 ease-in-out border-2 rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
            classes:
                "text-beigene-grey placeholder-beigene-grey bg-white border-beigene-blue-input focus:border-beigene-light-blue",
            variants: {
                danger:
                    "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
                success:
                    "border-green-300 bg-green-50 placeholder-gray-400 text-green-900"
            }
        }
    },
    "t-radio": {
        component: TRadio,
        props: {
            fixedClasses: "",
            classes:
                "form-radio text-white focus:ring-offset-0 focus:border-beigene-blue-input focus:ring-0 border-2 border-beigene-blue-input w-6 h-6 m-1 checked:border-beigene-blue-input hover:border-beigene-blue-input cursor-pointer",
            variants: {
                error: "text-red-500 border-red-300",
                success: "text-green-500 border-green-300"
            }
        }
    },
    "t-checkbox": {
        component: TChexbox,
        props: {
            wrapped: true,
            classes: {
                label: "text-sm uppercase mx-2 text-gray-700",
                input:
                    "text-white placeholder-white bg-white border-beigene-blue-input focus:border-beigene-light-blue",
                inputWrapper: "",
                wrapper: ""
            }
        }
    },
    // TODO: Needs to be styled correctly
    "t-select": {
        component: TSelect,
        props: {
            fixedClasses:
                "block w-full pl-3 pr-10 py-2 transition duration-100 ease-in-out border-2 rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
            classes:
                "text-beigene-grey placeholder-gray-400 bg-white border-beigene-blue-input focus:border-blue-500 ",
            variants: {
                danger:
                    "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
                success:
                    "border-green-300 bg-green-50 placeholder-gray-400 text-green-900"
            }
        }
    },
    // TODO: Needs to be styled correctly
    "t-textarea": {
        component: TTextarea,
        props: {
            fixedClasses:
                "block w-full px-3 py-2 transition duration-100 ease-in-out border-2 rounded-3xl shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
            classes:
                "text-beigene-grey placeholder-gray-400 bg-white border-beigene-blue-input focus:border-blue-500 ",
            variants: {
                danger:
                    "border-red-300 bg-red-50 placeholder-red-200 text-red-900",
                success:
                    "border-green-300 bg-green-50 placeholder-gray-400 text-green-900"
            }
        }
    }
};

// veevalidate
// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
localize("en", en);
// register components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(VueCryptojs);

Vue.use(Vuex);

Vue.use(VueTailwind, settings);

new Vue({
    data: function() {
        return {
            selectedStatus: ""
        };
    },
    i18n,
    router,
    store,
    ValidationProvider,
    ValidationObserver,
    render: h => h(App)
}).$mount("#app");
