import { BaseApiService } from "./apiBase";

export default class ProfileApiService extends BaseApiService {
    constructor() {
        super("profile");
    }

    async getProfile() {
        return this.fetch();
    }

    async updateProfile(data = {}) {
        const config = {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            data: JSON.stringify(data)
        };
        return this.fetch(config);
    }
}
