<template>
    <div class="flex max-w-screen-xl w-full layerBG">
        <div class="w-full my-4 mx-4 2xl:mx-0">
            <div class="container mx-auto text-center overflow-y-auto">
                <h1
                    class="text-beigene-blue font-bold mb-7"
                    v-html="$t('legalnotice.welcome')"
                ></h1>
                <div class="mb-12 text-2xl mx-auto messages-title">
                    <p v-html="$t('legalnotice.title')"></p>
                    <p
                        class="mt-2 text-xl"
                        v-html="
                            $t('legalnotice.email', {
                                link:
                                    '<a target=\'_blank\' href=\'https://www.beigene.com/contact-us/\' class=\'text-beigene-blue\'>' +
                                    $t('legalnotice.emailLabel') +
                                    '</a>'
                            })
                        "
                    ></p>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
main form .container .messages-title {
    max-width: 50rem;
}
</style>
<script>
import store from "../store/store";
import router from "../router";
import axios from "axios";
import qs from "qs";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import AgendaContent from "./content/AgendaContent";

const url = process.env.VUE_APP_API_URL;

export default {
    components: { AgendaContent },
    data() {
        return {
            errorMessage: "",
            errors: "",
            loginEnabled:
                process.env.VUE_APP_LOGIN_ENABLED === "true" ? true : false,
            regEnabled:
                process.env.VUE_APP_REGISTRATION_ENABLED === "true"
                    ? true
                    : false
        };
    },
    methods: {
        login() {
            router.push("/login");
        },
        register() {
            router.push("/register");
        }
    }
};
</script>
