<template>
    <div>
        <ul class="flex flex-row space-x-5">
            <NavigationSubItem
                v-if="$route.path.startsWith('/content')"
                :label="$t('global.menu.sub.myProfile')"
                link="/content/my-profile"
                ><IconUser
            /></NavigationSubItem>
            <NavigationSubItem
                v-if="
                    !$route.path.startsWith('/content') &&
                        loginEnabled &&
                        regEnabled
                "
                :label="$t('global.menu.main.login')"
                link="/content/home"
                ><IconLogin
            /></NavigationSubItem>
            <NavigationSubItem
                v-if="$route.path.startsWith('/content')"
                :label="$t('global.menu.sub.logout')"
                link="/content/logout"
                ><IconLogout
            /></NavigationSubItem>
        </ul>
    </div>
</template>

<script>
import NavigationSubItem from "./NavigationSubItem";
import IconUser from "./icons/User";
import IconLogin from "./icons/Login";
import IconLogout from "./icons/Logout";

export default {
    name: "NavigationSub",
    components: {
        NavigationSubItem,
        IconUser,
        IconLogin,
        IconLogout
    },
    data() {
        return {
            loginEnabled:
                process.env.VUE_APP_LOGIN_ENABLED === "true" ? true : false,
            regEnabled:
                process.env.VUE_APP_REGISTRATION_ENABLED === "true"
                    ? true
                    : false
        };
    }
};
</script>

<style></style>
