<template>
    <div class="w-full m-4 mb-0 md:m-12 md:mt-11 md:mb-0 relative">
        <div class="flex flex-col lg:flex-row lg:gap-12 max-w-7xl">
            <div class="flex flex-col xl:flex-1">
                <div
                    class="font-bold text-2xl text-beigene-midnight mb-4 lg:min-h-16"
                >
                    Company-related materials
                </div>
                <TileContainer class="mb-4">
                    <Tile
                        color="white"
                        background="white"
                        overlayColor="beigene-midnight"
                        image="thumbs/Preview_Pipeline.png"
                        openFlipHtml="https://online.fliphtml5.com/elkzy/ykda/"
                        ><template #label
                            >BeiGene Clinical Static Pipeline</template
                        ></Tile
                    >
                    <Tile
                        color="white"
                        background="white"
                        overlayColor="beigene-midnight"
                        image="thumbs/Preview_Video_MoA.png"
                        open3QSDN="ed5b90dd-8e20-11ea-97a4-002590c750be"
                        ><template #label
                            >Zanubrutinib MoA Video</template
                        ></Tile
                    >
                </TileContainer>
            </div>
            <div class="flex flex-col xl:flex-1">
                <div
                    class="font-bold text-2xl text-beigene-greystone mb-4 lg:min-h-16"
                >
                    Educational materials
                </div>
                <TileContainer class="mb-4">
                    <Tile
                        background="white"
                        overlayColor="beigene-greystone"
                        image="thumbs/Preview_CLL_eBook.png"
                        openFlipHtml="https://online.fliphtml5.com/elkzy/bmoy/"
                        ><template #label
                            >Fast Facts Chronic Lymphocytic Leukemia 2<sup
                                >nd</sup
                            >
                            edition</template
                        ></Tile
                    >
                </TileContainer>
            </div>
            <div class="flex flex-col xl:flex-1">
                <div
                    class="font-bold text-2xl text-beigene-cerulean-blue mb-4 lg:min-h-16"
                >
                    Meeting-related materials
                </div>
                <TileContainer class="mb-4">
                    <Tile
                        color="white"
                        background="white"
                        overlayColor="beigene-cerulean-blue"
                        image="thumbs/Preview_Agenda.png"
                        openFlipHtml="https://online.fliphtml5.com/elkzy/qkrn/"
                        ><template #label
                            >BeiGene Hematology Summit Agenda
                        </template></Tile
                    >
                    <Tile
                        color="white"
                        background="white"
                        overlayColor="beigene-cerulean-blue"
                        image="thumbs/Preview_Directions.png"
                        openFlipHtml="https://online.fliphtml5.com/elkzy/qkox/"
                        ><template #label
                            >Directions to meeting venue</template
                        ></Tile
                    >
                    <Tile
                        color="white"
                        background="white"
                        overlayColor="beigene-cerulean-blue"
                        image="thumbs/Preview_Company_Leaflet.png"
                        openFlipHtml="https://online.fliphtml5.com/elkzy/qgbs/"
                        ><template #label
                            >Europe Corporate Leaflet 2024</template
                        ></Tile
                    >
                </TileContainer>
            </div>
        </div>
    </div>
</template>
<style>
@media (min-width: 1024px) {
    .lg\:min-h-16 {
        min-height: 4rem;
    }
}
</style>
<script>
import TileContainer from "../../components/TileContainer";
import Tile from "../../components/Tile";

export default {
    name: "Resources",
    components: {
        TileContainer,
        Tile,
    },
    methods: {
        openPopup: function (type, content) {
            this.$root.$emit("openPopup", type, content);
        },
    },
};
</script>
