<template>
    <ValidationObserver
        v-slot="{ handleSubmit }"
        class="flex max-w-screen-xl w-full layerBG"
    >
        <form
            @submit.prevent="handleSubmit(send)"
            class="w-full my-4 mx-4 2xl:mx-0"
            id="userInputs"
        >
            <div class="grid grid-cols-24" v-if="successRegistration != false">
                <div class="col-span-24 mb-6">
                    <h1
                        class="text-beigene-blue font-bold text-center w-auto"
                        v-html="$t('messages.registerSuccessNew')"
                    ></h1>
                    <div
                        class="col-span-24 text-beigene-grey text-2xl text-center mb-2 mt-6"
                    >
                        <p v-html="$t('messages.registerSuccess')"></p>
                        <t-button
                            v-on:click="login"
                            type="button"
                            class="inline rounded-full w-48 h-12 text-xl font-semibold mt-6"
                            >{{ $t("basics.login") }}</t-button
                        >
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-24" v-if="successRegistration != true">
                <div class="col-span-24 mb-6">
                    <h1
                        class="text-beigene-blue font-bold text-center w-auto"
                        v-html="$t('messages.welcome')"
                    ></h1>
                </div>

                <div
                    class="col-span-24 text-beigene-grey text-2xl text-center mb-2"
                >
                    <p v-html="$t('messages.titleRegister')"></p>
                    <p
                        class="mt-2 text-xl"
                        v-html="
                            $t('basics.viewLocation', {
                                link:
                                    '<a target=\'_blank\' href=\'https://maps.app.goo.gl/cnrbaqS1NUpzeDmw8\' class=\'text-beigene-blue\'>' +
                                    $t('basics.viewLocationLabel') +
                                    '</a>',
                            })
                        "
                    ></p>
                    <p
                        v-html="$t('messages.registerYes')"
                        class="mt-6 mb-6"
                    ></p>
                </div>

                <!-- Left column -->
                <div
                    class="col-span-24 md:col-span-11 grid grid-cols-13 md:grid-cols-11 gap-x-4 gap-y-8 mb-8"
                >
                    <!-- Firstname -->
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-5 md:col-span-3 sm:text-right"
                    >
                        <label
                            for="firstname"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.nameFirst") }}*
                        </label>
                    </div>
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                    >
                        <validation-provider
                            name="firstname"
                            rules="required|min:2"
                            v-slot="{ errors }"
                            mode="aggressive"
                        >
                            <t-input
                                type="text"
                                id="firstname"
                                name="firstname"
                                class="px-3 py-2 rounded-full h-10 w-full"
                                v-model="firstname"
                            />
                            <div
                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                            >
                                {{
                                    errors[0]
                                        ? errors[0].replace(
                                              "firstname",
                                              "first name"
                                          )
                                        : ""
                                }}
                            </div>
                        </validation-provider>
                    </div>

                    <!-- Lastname -->
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-5 md:col-span-3 sm:text-right"
                    >
                        <label
                            for="lastname"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.nameLast") }}*
                        </label>
                    </div>
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                    >
                        <validation-provider
                            name="lastname"
                            rules="required|min:2"
                            v-slot="{ errors }"
                            mode="aggressive"
                        >
                            <t-input
                                type="text"
                                id="lastname"
                                name="lastname"
                                class="px-3 py-2 rounded-full h-10 w-full"
                                v-model="lastname"
                            />
                            <div
                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                            >
                                {{
                                    errors[0]
                                        ? errors[0].replace(
                                              "lastname",
                                              "last name"
                                          )
                                        : ""
                                }}
                            </div>
                        </validation-provider>
                    </div>

                    <!-- E-Mail -->
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-5 md:col-span-3 sm:text-right"
                    >
                        <label
                            for="email"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.email") }}*
                        </label>
                    </div>
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                    >
                        <validation-provider
                            name="email"
                            rules="required|min:3|email"
                            v-slot="{ errors }"
                            mode="aggressive"
                        >
                            <t-input
                                type="email"
                                id="email"
                                name="email"
                                class="px-3 py-2 rounded-full h-10 w-full"
                                v-model="email"
                            />
                            <div
                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                            >
                                {{
                                    errors[0]
                                        ? errors[0].replace("email", "e-mail")
                                        : ""
                                }}
                            </div>
                        </validation-provider>
                    </div>
                </div>

                <!-- Right column -->
                <div
                    class="col-span-24 md:col-span-13 grid grid-cols-13 gap-x-4 gap-y-8 mb-8"
                >
                    <!-- Password -->
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-5 sm:text-right"
                    >
                        <label
                            for="password"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.password") }}*</label
                        >
                    </div>
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                    >
                        <validation-provider
                            name="password"
                            rules="required|min:3"
                            v-slot="{ errors }"
                            mode="aggressive"
                        >
                            <t-input
                                type="password"
                                id="password"
                                name="password"
                                class="px-3 py-2 rounded-full h-10 w-full"
                                v-model="password"
                            />

                            <div
                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                            >
                                {{ errors[0] }}
                            </div>
                        </validation-provider>
                    </div>
                    <!-- Retype Password -->
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-5 sm:text-right"
                    >
                        <label
                            for="retype_password"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.passwordRetype") }}*</label
                        >
                    </div>
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                    >
                        <validation-provider
                            name="retype_password"
                            rules="required|confirmed:password"
                            v-slot="{ errors }"
                            mode="aggressive"
                        >
                            <t-input
                                type="password"
                                id="retype_password"
                                name="retype_password"
                                class="px-3 py-2 rounded-full h-10 w-full"
                                v-model="retypepassword"
                            />
                            <div
                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                            >
                                {{
                                    errors[0]
                                        ? errors[0].replace(
                                              "retype_password",
                                              "password re-type"
                                          )
                                        : ""
                                }}
                            </div>
                        </validation-provider>
                    </div>
                    <!-- Institution -->
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-5 sm:text-right"
                    >
                        <label
                            for="institution"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.institution") }}</label
                        >
                    </div>
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                    >
                        <t-input
                            type="text"
                            id="institution"
                            name="institution"
                            class="px-3 py-2 rounded-full h-10 w-full"
                            v-model="institution"
                        />
                    </div>
                </div>

                <!-- second row -->
                <!-- left columm -->
                <div
                    class="col-span-24 md:col-span-11 grid grid-cols-13 md:grid-cols-11 gap-x-4 gap-y-8 mb-8"
                >
                    <!-- Country -->
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-5 md:col-span-3 sm:text-right"
                    >
                        <label
                            for="country"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.country") }}*
                        </label>
                    </div>
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                    >
                        <validation-provider
                            name="country"
                            rules="required|min:2"
                            v-slot="{ errors }"
                            mode="aggressive"
                        >
                            <select
                                id="country"
                                name="country"
                                class="block w-full px-3 py-2 transition duration-100 ease-in-out border-2 rounded-3xl shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 rounded-full h-10 w-full text-beigene-grey placeholder-gray-400 bg-white border-beigene-blue-input focus:border-blue-500"
                                v-model="miscCountry"
                            >
                                <option value="" disabled selected>
                                    Please select
                                </option>
                                <option
                                    v-for="country in countries"
                                    :key="country.name"
                                    :value="country['alpha-2']"
                                >
                                    {{ country.name }}
                                </option>
                            </select>
                            <div
                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                            >
                                {{ errors[0] }}
                            </div>
                        </validation-provider>
                    </div>
                </div>

                <!-- right column -->
                <div
                    class="col-span-24 md:col-span-13 grid grid-cols-13 gap-x-4 gap-y-8 mb-8"
                >
                    <!-- beigene contact -->
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-5 sm:text-right"
                    >
                        <label
                            for="miscContactBeigene"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.contactBeigene") }}</label
                        >
                    </div>
                    <div
                        class="md:h-11 xl:h-auto col-span-13 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                    >
                        <validation-provider
                            name="beigenecontact"
                            rules="min:0|alpha_spaces"
                            v-slot="{ errors }"
                            mode="aggressive"
                        >
                            <t-input
                                type="text"
                                rules="min:0|alpha_spaces"
                                id="miscContactBeigene"
                                name="miscContactBeigene"
                                class="px-3 py-2 rounded-full h-10 w-full"
                                v-model="miscContactBeigene"
                            />
                            <div
                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                            >
                                {{
                                    errors[0]
                                        ? errors[0].replace(
                                              "beigenecontact",
                                              "BeiGene Contact"
                                          )
                                        : ""
                                }}
                            </div>
                        </validation-provider>
                    </div>
                </div>
                <!-- / second row -->

                <!-- third row -->
                <div
                    class="col-span-24 md:col-span-24 grid grid-cols-13 md:grid-cols-12 gap-x-4 gap-y-8 mb-8 sm:border-t-2 sm:border-beigene-blue-input sm:pt-4 sm:mt-4"
                >
                    <!-- dietary restrictions -->
                    <div
                        class="md:col-start-2 md:h-13 xl:h-auto col-span-9 sm:col-span-6 md:col-span-4 sm:text-right"
                    >
                        <label
                            for="dietaryRestrictions"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.dietaryRestrictions") }}
                        </label>
                    </div>
                    <div
                        class="md:h-13 xl:h-auto col-span-13 md:col-span-4 sm:col-span-6 flex flex-col justify-center relative -mt-6 sm:mt-6"
                    >
                        <t-textarea
                            id="dietaryRestrictions"
                            name="dietaryRestrictions"
                            class="px-3 py-2 h-auto w-full"
                            rows="4"
                            v-model="miscDietaryRestrictions"
                        />
                    </div>
                </div>

                <!-- fourth row -->
                <div
                    class="col-span-24 md:col-span-24 grid grid-cols-13 md:grid-cols-12 gap-x-4 gap-y-8 mb-8 sm:border-t-2 sm:border-beigene-blue-input sm:pt-4 sm:mt-4"
                >
                    <!-- Shuttle service IFEMA to Hotel -->
                    <div
                        class="md:col-start-2 md:h-13 xl:h-auto col-span-9 sm:col-span-6 md:col-span-4 sm:text-right"
                    >
                        <label
                            for="shuttleServiceIfemaHotel"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.shuttleServiceIfemaHotel") }}</label
                        >
                    </div>
                    <div
                        class="md:h-13 xl:h-auto col-span-13 md:col-span-4 sm:col-span-6 flex flex-col justify-center"
                    >
                        <validation-provider
                            name="miscShuttleServiceIfemaHotel"
                            rules="oneOf:true,false"
                            v-slot="{ errors }"
                            mode="aggressive"
                        >
                            <div class="flex justify-center">
                                <span class="flex flex-row justify-left">
                                    <t-radio
                                        type="radio"
                                        id="shuttleServiceIfemaHotel-yes"
                                        name="shuttleServiceIfemaHotel"
                                        value="true"
                                        class="m-2 ml-0 sm:ml-4"
                                        v-model="miscShuttleServiceIfemaHotel"
                                    />
                                    <label
                                        for="yes"
                                        class="w-auto m-1 text-2xl"
                                        >{{ $t("basics.yes") }}</label
                                    >
                                    <t-radio
                                        type="radio"
                                        id="shuttleServiceIfemaHotel-no"
                                        name="shuttleServiceIfemaHotel"
                                        value="false"
                                        class="m-2 ml-4"
                                        v-model="miscShuttleServiceIfemaHotel"
                                    />
                                    <label
                                        for="no"
                                        class="w-auto m-1 text-2xl"
                                        >{{ $t("basics.no") }}</label
                                    >
                                </span>
                            </div>
                            <div
                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                            >
                                {{
                                    errors[0]
                                        ? errors[0].replace(
                                              "miscShuttleServiceIfemaHotel",
                                              "shuttle service"
                                          )
                                        : ""
                                }}
                            </div>
                        </validation-provider>
                    </div>

                    <!-- Shuttle service Hotel to City -->
                    <div
                        class="md:col-start-2 md:h-13 xl:h-auto col-span-9 sm:col-span-6 md:col-span-4 sm:text-right"
                    >
                        <label
                            for="shuttleServiceHotelCity"
                            class="h-full inline-block flex flex-col justify-center text-2xl"
                            >{{ $t("basics.shuttleServiceHotelCity") }}</label
                        >
                    </div>
                    <div
                        class="md:h-13 xl:h-auto col-span-13 md:col-span-4 sm:col-span-6 flex flex-col justify-center"
                    >
                        <validation-provider
                            name="shuttleServiceHotelCity"
                            rules="oneOf:true,false"
                            v-slot="{ errors }"
                            mode="aggressive"
                        >
                            <div class="flex justify-center">
                                <span class="flex flex-row justify-left">
                                    <t-radio
                                        type="radio"
                                        id="shuttleServiceHotelCity-yes"
                                        name="shuttleServiceHotelCity"
                                        value="true"
                                        class="m-2 ml-0 sm:ml-4"
                                        v-model="miscShuttleServiceHotelCity"
                                    />
                                    <label
                                        for="yes"
                                        class="w-auto m-1 text-2xl"
                                        >{{ $t("basics.yes") }}</label
                                    >
                                    <t-radio
                                        type="radio"
                                        id="shuttleServiceHotelCity-no"
                                        name="shuttleServiceHotelCity"
                                        value="false"
                                        class="m-2 ml-4"
                                        v-model="miscShuttleServiceHotelCity"
                                    />
                                    <label
                                        for="no"
                                        class="w-auto m-1 text-2xl"
                                        >{{ $t("basics.no") }}</label
                                    >
                                </span>
                            </div>
                            <div
                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                            >
                                {{
                                    errors[0]
                                        ? errors[0].replace(
                                              "miscShuttleServiceIfemaHotel",
                                              "shuttle service"
                                          )
                                        : ""
                                }}
                            </div>
                        </validation-provider>
                    </div>
                </div>

                <div
                    class="col-span-24 w-full lg:w-3/3 mb-8 px-32 md:px-88 lg:px-96"
                >
                    <t-button
                        v-if="APIcallInProgress === true"
                        class="inline rounded-full w-36 lg:w-48 h-12 text-xl font-semibold bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed float-right"
                        >{{ $t("basics.loading") }}</t-button
                    >
                    <t-button
                        v-else
                        class="inline rounded-full w-36 lg:w-48 h-12 text-xl font-semibold float-right"
                        type="submit"
                        >{{ $t("basics.send") }}</t-button
                    >
                    <t-button
                        class="inline border-3 border-beigene-blue rounded-full w-36 lg:w-48 h-12 text-beigene-blue bg-opacity-0 shadow-sm hover:bg-beigene-blue hover:text-white hover:border-beigene-light-blue text-xl font-bold"
                        @click="goBack"
                        >{{ $t("basics.back") }}</t-button
                    >
                </div>
                <div
                    class="col-span-24 text-center text-red-700 font-semibold relative"
                >
                    <p
                        v-if="errorMessage"
                        class="absolute w-full -mt-6 text-red-700 font-semibold text-center"
                    >
                        {{ errorMessage }}
                    </p>
                </div>

                <div class="col-span-24 text-center text-2xl">
                    <p
                        class="mt-8 text-2xl"
                        v-html="
                            $t('basics.issues', {
                                link:
                                    '<a target=\'_blank\' title=\'support@infill.com\' href=\'mailto:support@infill.com?subject=' +
                                    encodeURIComponent(
                                        $t('basics.issuesMailSubject')
                                    ) +
                                    '\' class=\'text-beigene-blue\'>' +
                                    $t('basics.issuesLabel') +
                                    '</a>',
                            })
                        "
                    ></p>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>
<style></style>
<script>
import store from "../store/store";
import router from "../router";
import axios from "axios";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
import countryData from "../assets/countries.json";

const url = process.env.VUE_APP_API_URL;

export default {
    data: function () {
        return {
            errorMessage: "",
            errors: "",
            password: "",
            retypepassword: "",
            APIcallInProgress: false,
            successMessage: "",
            successRegistration: false,
            countries: countryData,
        };
    },
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    methods: {
        login() {
            router.push("/login");
        },
        goBack() {
            router.push("/");
        },
        async send() {
            if (this.errors == "") {
                // get password from input
                const userInputForm = document.getElementById("userInputs");
                const passwordFromUser = userInputForm.querySelector(
                    "input[name=password]"
                );
                const passwordRetypeFromUser = userInputForm.querySelector(
                    "input[name=retype_password]"
                );

                const axiosDataRegistration = {
                    email: store.state.email,
                    firstname: store.state.firstname,
                    lastname: store.state.lastname,
                    country: store.state.miscCountry,
                    password: passwordFromUser.value,
                    passwordConfirm: passwordRetypeFromUser.value,
                    language: "en",
                    organizationKey: "beigene",
                    agbAccepted: true,
                    misc: {
                        institution: store.state.institution,
                        beigeneContact: store.state.miscContactBeigene,
                        dietaryRestrictions:
                            store.state.miscDietaryRestrictions,
                        shuttleServiceIfemaHotel:
                            store.state.miscShuttleServiceIfemaHotel,
                        shuttleServiceHotelCity:
                            store.state.miscShuttleServiceHotelCity,
                    },
                };

                this.APIcallInProgress = true;

                try {
                    const limits =
                        await this.$api.registration.limits.beigene();

                    if (limits.registrationLimitReached) {
                        this.APIcallInProgress = false;

                        this.errorMessage = this.$i18n
                            .t("messagesError.registrationLimitReached")
                            .replace("<br/>", "");
                        return;
                    }
                } catch (e) {
                    this.APIcallInProgress = false;

                    this.errorMessage = this.$i18n.t(
                        "messagesError.somethingWrong"
                    );
                    return;
                }

                // registration action
                axios
                    .post(url + "api/v1/registration", axiosDataRegistration, {
                        withCredentials: false,
                    })
                    .then((response) => {
                        if (response.status == 200) {
                            this.successMessage = this.$i18n.t(
                                "messages.registerSuccess"
                            );
                            this.successRegistration = true;

                            // clear VUEX item
                            this.$store.commit("emailAdress", "");
                            this.$store.commit("userFirstName", "");
                            this.$store.commit("userLastName", "");
                            this.$store.commit("userInstitution", "");
                            this.$store.commit("updateMiscCountry", "");
                            this.$store.commit("updateMiscContactBeigene", "");
                            this.$store.commit(
                                "updateMiscDietaryRestrictions",
                                ""
                            );
                            this.$store.commit(
                                "updateMiscShuttleServiceIfemaHotel",
                                false
                            );
                            this.$store.commit(
                                "updateMiscShuttleServiceHotelCity",
                                false
                            );
                            // router.push("/login");
                            this.APIcallInProgress = false;
                        }
                    })
                    .catch((error) => {
                        if (
                            error.response.data.code ===
                            "registration_user_exists"
                        ) {
                            this.errorMessage = this.$i18n.t(
                                "messagesError.alreadyRegistered"
                            );
                        } else {
                            this.errorMessage = this.$i18n.t(
                                "messagesError.somethingWrong"
                            );
                        }

                        this.APIcallInProgress = false;
                    });
            } else {
                if (store.state.userRegistrationType == "") {
                    this.APIcallInProgress = false;
                    this.errorMessage = this.$i18n.t(
                        "messagesError.noAttendStatus"
                    );
                } else {
                    this.APIcallInProgress = false;
                    this.errorMessage = this.$i18n.t(
                        "messagesError.somethingWrong"
                    );
                }
            }
        },
    },
    computed: {
        email: {
            get() {
                return this.$store.state.email;
            },
            set(value) {
                this.$store.commit("emailAdress", value);
            },
        },
        firstname: {
            get() {
                return this.$store.state.firstname;
            },
            set(value) {
                this.$store.commit("userFirstName", value);
            },
        },
        lastname: {
            get() {
                return this.$store.state.lastname;
            },
            set(value) {
                this.$store.commit("userLastName", value);
            },
        },
        institution: {
            get() {
                return this.$store.state.institution;
            },
            set(value) {
                this.$store.commit("userInstitution", value);
            },
        },
        miscCountry: {
            get() {
                return this.$store.state.miscCountry;
            },
            set(value) {
                this.$store.commit("updateMiscCountry", value);
            },
        },
        miscContactBeigene: {
            get() {
                return this.$store.state.miscContactBeigene;
            },
            set(value) {
                this.$store.commit("updateMiscContactBeigene", value);
            },
        },
        miscDietaryRestrictions: {
            get() {
                return this.$store.state.miscDietaryRestrictions;
            },
            set(value) {
                this.$store.commit("updateMiscDietaryRestrictions", value);
            },
        },
        miscShuttleServiceIfemaHotel: {
            get() {
                return this.$store.state.miscShuttleServiceIfemaHotel;
            },
            set(value) {
                this.$store.commit("updateMiscShuttleServiceIfemaHotel", value);
            },
        },
        miscShuttleServiceHotelCity: {
            get() {
                return this.$store.state.miscShuttleServiceHotelCity;
            },
            set(value) {
                this.$store.commit("updateMiscShuttleServiceHotelCity", value);
            },
        },
    },
};
</script>
