var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex max-w-screen-xl w-full layerBG"},[_c('div',{staticClass:"w-full my-4 mx-4 2xl:mx-0"},[_c('div',{staticClass:"container mx-auto text-center overflow-y-auto"},[_c('h1',{staticClass:"text-beigene-blue font-bold mb-7",domProps:{"innerHTML":_vm._s(_vm.$t('legalnotice.welcome'))}}),_c('div',{staticClass:"mb-12 text-2xl mx-auto messages-title"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('legalnotice.title'))}}),_c('p',{staticClass:"mt-2 text-xl",domProps:{"innerHTML":_vm._s(
                        _vm.$t('legalnotice.email', {
                            link:
                                '<a target=\'_blank\' href=\'https://www.beigene.com/contact-us/\' class=\'text-beigene-blue\'>' +
                                _vm.$t('legalnotice.emailLabel') +
                                '</a>'
                        })
                    )}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }