<template>
    <nav class="bg-white shadow-lg">
        <div class="h-auto">
            <ul class="p-10">
                <NavigationMainItem
                    :label="$t('global.menu.main.home')"
                    link="/content/home"
                    ><IconHomeS
                /></NavigationMainItem>

                <NavigationMainItemSeparator v-if="agendaEnabled" />

                <NavigationMainItem
                    v-if="agendaEnabled"
                    :label="$t('global.menu.main.agenda')"
                    link="/content/agenda"
                    ><IconAgenda
                /></NavigationMainItem>

                <NavigationMainItemSeparator v-if="resourcesEnabled" />

                <NavigationMainItem
                    v-if="resourcesEnabled"
                    :label="$t('global.menu.main.resources')"
                    link="/content/resources"
                    ><IconFolderS
                /></NavigationMainItem>

                <NavigationMainItemSeparator />

                <NavigationMainItem
                    :label="$t('global.menu.main.contactUs')"
                    link="/content/contact-us"
                    ><IconMailS
                /></NavigationMainItem>

                <!-- Mobile only -->
                <NavigationMainItemSeparator class="xl:hidden" />

                <NavigationMainItem
                    :label="$t('global.menu.main.myProfile')"
                    link="/content/my-profile"
                    class="xl:hidden"
                    ><IconUserS
                /></NavigationMainItem>

                <NavigationMainItem
                    :label="$t('global.menu.main.logout')"
                    link="/content/logout"
                    class="xl:hidden"
                    ><IconLogoutS
                /></NavigationMainItem>
            </ul>
        </div>
    </nav>
</template>

<script>
import NavigationMainItem from "./NavigationMainItem";
import NavigationMainItemSeparator from "./NavigationMainItemSeparator";
import IconHomeS from "./icons/HomeS";
import IconStatusOnlineS from "./icons/StatusOnlineS";
import IconViewGridS from "./icons/ViewGridS";
import IconUserGroupS from "./icons/UserGroupS";
import IconFolderS from "./icons/FolderS";
import IconMailS from "./icons/MailS";
import IconUserS from "./icons/UserS";
import IconLogoutS from "./icons/LogoutS";
import IconAgenda from "./icons/Agenda";

export default {
    name: "NavigationMain",
    components: {
        NavigationMainItem,
        NavigationMainItemSeparator,
        IconHomeS,
        IconStatusOnlineS,
        IconViewGridS,
        IconUserGroupS,
        IconFolderS,
        IconMailS,
        IconUserS,
        IconLogoutS,
        IconAgenda
    },
    data() {
        return {
            resourcesEnabled:
                process.env.VUE_APP_RESOURCES_ENABLED === "true" ? true : false,
            agendaEnabled:
                process.env.VUE_APP_AGENDA_ENABLED === "true" ? true : false
        };
    }
};
</script>

<style>
.nav-open {
    background-color: rgba(255, 255, 255, 1);
    animation: slide-in 0.3s ease-out;
}

.nav-close {
    animation: slide-out 0.35s ease-out;
    transform: translateX(-100%);
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-out {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100%);
    }
}
</style>
