<template>
    <transition name="fade">
        <div
            v-if="isOpen"
            class="fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-50 z-50 popup"
        >
            <div class="popup-overlay"></div>
            <div class="popup-container w-full h-full p-4 flex">
                <div
                    class="popup-content w-full h-full max-w-1432 max-h-820 m-auto p-4 bg-black relative flex flex-col"
                >
                    <div
                        v-if="title"
                        class="text-white text-lg text-center mb-2"
                    >
                        {{ title }}
                    </div>

                    <FlipHTMLFive
                        class="flex"
                        v-if="type === 'fliphtml5'"
                        :src="content"
                    />

                    <div
                        v-if="type === 'image'"
                        class="flex flex-col flex-grow bg-contain bg-center bg-no-repeat"
                        v-bind:style="backgroundStyle"
                    ></div>

                    <div
                        v-if="type === '3qsdn'"
                        class="flex flex-col flex-grow justify-center min-h-0 z-0 relative"
                        id="threeQvideoPlayerContainer"
                    >
                        <div
                            id="threeQvideoPlayer"
                            ref="threeQvideoPlayer"
                        ></div>
                    </div>

                    <div
                        v-if="type === 'text'"
                        class="flex flex-col flex-grow justify-center items-center text-white text-lg"
                    >
                        {{ content }}
                    </div>

                    <div
                        v-if="subtitle"
                        class="text-white text-lg text-center mt-2"
                    >
                        {{ subtitle }}
                    </div>

                    <IconClose
                        v-on:click.native="closePopup()"
                        class="cursor-pointer p-1 absolute w-11 h-11 text-white right-5 top-5"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>

<script>
import IconClose from "../components/icons/Close";
import FlipHTMLFive from "../components/FlipHTMLFive";

export default {
    name: "PopupOverlay",
    components: {
        IconClose,
        FlipHTMLFive
    },
    data() {
        return {
            type: null,
            isOpen: false,
            content: null,
            title: null,
            subtitle: null
        };
    },
    computed: {
        backgroundStyle: function() {
            if (this.content) {
                return {
                    backgroundImage: `url(${this.content})`
                };
            }

            return {};
        }
    },
    mounted() {
        this.$root.$on(
            "openPopup",
            async (type, content, title = null, subtitle = null) => {
                this.type = type;
                this.content = content;
                this.title = title;
                this.subtitle = subtitle;
                this.isOpen = true;

                await this.$nextTick();

                if (this.type === "3qsdn") {
                    if (this.$refs.threeQvideoPlayer) {
                        const js3qSettings = {
                            dataid: content,
                            container: this.$refs.threeQvideoPlayer.id
                        };

                        this.js3qVideoPlayer = new js3q(js3qSettings);
                    }
                }
            }
        );
    },
    methods: {
        closePopup() {
            this.destroy3DSQN();

            this.type = null;
            this.content = null;
            this.title = null;
            this.subtitle = null;
            this.isOpen = false;
        },
        destroy3DSQN() {
            if (
                this.type === "3qsdn" &&
                this.$refs.threeQvideoPlayer &&
                this.js3qVideoPlayer
            ) {
                this.js3qVideoPlayer.destroy();
            }
        }
    },
    beforeDestroy() {
        this.destroy3DSQN();
    }
};
</script>
