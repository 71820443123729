<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
    ></svg>
</template>

<script>
export default {
    name: "IconEmpty"
};
</script>
