var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path.startsWith('/content')),expression:"$route.path.startsWith('/content')"}],staticClass:"relative sm:max-w-xl ml-auto h-0"},[_c('nav',{staticClass:"border-3 border-white rounded"},[_c('button',{staticClass:"w-14 h-14 relative focus:outline-none bg-beigene-blue rounded",on:{"click":_vm.toogleNav}},[_c('div',{staticClass:"block w-5 absolute left-6 top-1/2 transform -translate-x-1/2 -translate-y-1/2"},[_c('span',{staticClass:"block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out",class:{
                        'rotate-45':
                            this.$store.state.toogleShowHideNavigation ===
                            true,
                        ' -translate-y-1.5':
                            this.$store.state.toogleShowHideNavigation ===
                            false
                    }}),_c('span',{staticClass:"block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out",class:{
                        'opacity-0':
                            this.$store.state.toogleShowHideNavigation ===
                            true
                    }}),_c('span',{staticClass:"block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out",class:{
                        '-rotate-45':
                            this.$store.state.toogleShowHideNavigation ===
                            true,
                        ' translate-y-1.5':
                            this.$store.state.toogleShowHideNavigation ===
                            false
                    }})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }