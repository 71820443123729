import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
import storePlugins from "@/plugins/storePlugins";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        storePlugins,
        createPersistedState({
            storage: {
                getItem: key => Cookies.get(key),
                setItem: (key, value) =>
                    // basic setup
                    // secure -> https
                    // samesite -> always strict
                    Cookies.set(key, value, {
                        expires: 1,
                        secure: true,
                        samesite: "strict"
                    }),
                removeItem: key => Cookies.remove(key)
            }
        })
    ],
    state: {
        email: "",
        firstname: "",
        lastname: "",
        institution: "",
        jwt: "",
        accessToken: "",
        accessTokenExpiresAt: "",
        registerYesID: "",
        toogleActive: false,
        toogleShowHideNavigation: false,
        miscCountry: "",
        miscContactBeigene: "",
        miscDietaryRestrictions: "",
        miscShuttleServiceIfemaHotel: false,
        miscShuttleServiceHotelCity: false
    },
    mutations: {
        emailAdress(state, email) {
            state.email = email;
        },
        userFirstName(state, firstname) {
            state.firstname = firstname;
        },
        userLastName(state, lastname) {
            state.lastname = lastname;
        },
        userInstitution(state, institution) {
            state.institution = institution;
        },
        updateAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        },
        updateJWT(state, jwt) {
            state.jwt = jwt;
        },
        updateAccessTokenExpiresAt(state, accessTokenExpiresAt) {
            state.accessTokenExpiresAt = accessTokenExpiresAt;
        },
        clearAccess(state) {
            state.accessToken = "";
            state.accessTokenExpiresAt = "";
        },
        clearAccessCancelParticipation(state) {
            state.accessToken = "";
            state.accessTokenExpiresAt = "";
            state.email = "";
            state.firstname = "";
            state.lastname = "";
            state.jwt = "";
        },
        // check user auth
        registerYesID(state, registerYesID) {
            state.registerYesID = registerYesID;
        },
        updateToogleStatus(state, toogleActive) {
            state.toogleActive = toogleActive;
        },
        updateNavigationStatus(state, toogleShowHideNavigation) {
            state.toogleShowHideNavigation = toogleShowHideNavigation;
        },
        updateMiscCountry(state, miscCountry) {
            state.miscCountry = miscCountry;
        },
        updateMiscContactBeigene(state, miscContactBeigene) {
            state.miscContactBeigene = miscContactBeigene;
        },
        updateMiscDietaryRestrictions(state, miscDietaryRestrictions) {
            state.miscDietaryRestrictions = miscDietaryRestrictions;
        },
        updateMiscShuttleServiceIfemaHotel(
            state,
            miscShuttleServiceIfemaHotel
        ) {
            state.miscShuttleServiceIfemaHotel = miscShuttleServiceIfemaHotel;
        },
        updateMiscShuttleServiceHotelCity(state, miscShuttleServiceHotelCity) {
            state.miscShuttleServiceHotelCity = miscShuttleServiceHotelCity;
        }
    }
});
