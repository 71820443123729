<template>
    <li class="py-3">
        <hr class="border-gray-300" />
    </li>
</template>

<script>
export default {
    name: "NavigationMainItemSeparator"
};
</script>

<style></style>
