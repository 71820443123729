<template>
    <div class="flex max-w-screen-xl w-full layerBG">
        <div class="w-full my-4 mx-4 2xl:mx-0 text-center overflow-y-auto">
            <h1
                class="text-beigene-blue font-bold mb-8"
                v-html="$t('basics.forgotPasswordTitle')"
            ></h1>

            <div class="relative">
                <p class="mb-6 text-2xl">
                    {{ $t("basics.forgotPasswordMessage") }}
                </p>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form
                        @submit.prevent="handleSubmit(submitPassword)"
                        id="userInputs"
                    >
                        <div class="max-w-screen-sm mx-auto">
                            <div
                                class="grid grid-cols-12 gap-x-4 gap-y-8 mb-10"
                            >
                                <div
                                    class="md:col-start-1 md:h-11 xl:h-auto col-span-12 sm:col-span-3 text-left sm:text-right"
                                >
                                    <label
                                        for="email"
                                        class="h-full inline-block flex flex-col justify-center text-2xl"
                                        >{{ $t("basics.email") }}</label
                                    >
                                </div>

                                <div
                                    class="md:h-11 xl:h-auto col-span-12 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                                >
                                    <validation-provider
                                        name="email"
                                        rules="required|min:3|email"
                                        v-slot="{ errors }"
                                        mode="aggressive"
                                    >
                                        <t-input
                                            type="email"
                                            id="email"
                                            name="email"
                                            class="px-3 py-2 rounded-full h-10 w-full"
                                            v-model="email"
                                        />
                                        <div
                                            class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                                        >
                                            {{
                                                errors[0]
                                                    ? errors[0].replace(
                                                          "email",
                                                          "e-mail"
                                                      )
                                                    : ""
                                            }}
                                        </div>
                                    </validation-provider>
                                </div>
                            </div>

                            <div class="col-span-24">
                                <t-button
                                    v-if="errors[0] != '' && email == ''"
                                    class="mb-8 inline rounded-full w-48 h-12 text-xl font-semibold bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed"
                                    >{{
                                        $t("basics.forgotPasswordButton")
                                    }}</t-button
                                >

                                <t-button
                                    v-else-if="APIcallInProgress === true"
                                    class="mb-8 inline rounded-full w-48 h-12 text-xl font-semibold bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed"
                                    >{{ $t("basics.loading") }}</t-button
                                >

                                <t-button
                                    v-else-if="sendButtonDisabled === true"
                                    class="mb-8 inline rounded-full w-48 h-12 text-xl font-semibold bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed"
                                    >{{
                                        $t("basics.forgotPasswordButton")
                                    }}</t-button
                                >

                                <t-button
                                    v-else
                                    type="submit"
                                    class="mb-8 inline rounded-full w-48 h-12 text-xl font-semibold"
                                    >{{
                                        $t("basics.forgotPasswordButton")
                                    }}</t-button
                                >
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
                <div
                    v-if="successMessage"
                    class="col-span-24 text-center text-green-700 font-semibold relative"
                >
                    <p
                        class="absolute w-full -mt-6 text-green-700 font-semibold text-center"
                    >
                        {{ successMessage }}
                    </p>
                </div>
                <div
                    v-if="errorMessage"
                    class="col-span-24 text-center text-red-700 font-semibold relative"
                >
                    <p
                        class="absolute w-full -mt-6 text-red-700 font-semibold text-center"
                    >
                        {{ errorMessage }}
                    </p>
                </div>
                <p
                    class="mt-8 text-2xl"
                    v-html="
                        $t('basics.issues', {
                            link:
                                '<a target=\'_blank\' title=\'support@infill.com\' href=\'mailto:support@infill.com?subject=' +
                                encodeURIComponent(
                                    $t('basics.issuesMailSubject')
                                ) +
                                '\' class=\'text-beigene-blue\'>' +
                                $t('basics.issuesLabel') +
                                '</a>'
                        })
                    "
                ></p>
            </div>
        </div>
    </div>
</template>
<script>
import store from "../store/store";
import router from "../router";
import axios from "axios";
import qs from "qs";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

const url = process.env.VUE_APP_API_URL;

export default {
    data() {
        return {
            errorMessage: "",
            errors: "",
            email: "",
            successMessage: "",
            APIcallInProgress: false,
            sendButtonDisabled: false
        };
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    methods: {
        submitPassword() {
            if (this.errors == "") {
                // get email from input
                const userInputForm = document.getElementById("userInputs");
                const emailFromUser = userInputForm.querySelector(
                    "input[name=email]"
                );

                const axiosData = qs.stringify({
                    email: emailFromUser.value,
                    language: "en",
                    organizationKey: "beigene"
                });

                //console.log("Data: " + axiosData);
                this.APIcallInProgress = true;

                // post request
                axios
                    .post(url + "/api/v1/resetpassword", axiosData, {
                        withCredentials: false
                    })
                    .then(() => {
                        this.successMessage = this.$i18n.t(
                            "basics.forgotPasswordEmailSend"
                        );
                        this.APIcallInProgress = false;
                        this.sendButtonDisabled = true;
                    })
                    .catch(error => {
                        this.errorMessage = this.$i18n.t(
                            "messagesError.somethingWrong"
                        );
                    });
            } else {
                this.errorMessage = this.$i18n.t(
                    "messagesError.somethingWrong"
                );
            }
        }
    },
    computed: {}
};
</script>
