<template>
    <li class="sm:text-md sm:flex sm:flex-row">
        <router-link
            :to="link"
            active-class="text-opacity-60"
            class="flex flex-row space-x-2 text-white hover:text-opacity-60"
            ><div class="w-6 h-6">
                <slot
                    ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    ></svg
                ></slot>
            </div>
            <div class="font-semibold">{{ label }}</div></router-link
        >
    </li>
</template>

<script>
export default {
    name: "NavigationSubItem",
    props: ["label", "link"]
};
</script>
