<template>
    <header class="bg-beigene-blue">
        <nav
            class="flex justify-between w-full text-white p-4 pt-7 pb-6 pl-10 relative"
        >
            <router-link :to="homeLink">
                <img
                    class="block lg:inline h-9 m-1 lg:m-2"
                    src="/BeiGene_Logo.svg"
                />
                <span
                    class="block lg:inline font-normal text-3xl text-white lg:absolute lg:top-9 ml-1 lg:ml-5 page-title"
                    >{{ $t("basics.pageTitle") }}</span
                >
            </router-link>

            <NavigationSub
                class="invisible xl:visible absolute top-11 right-10"
            />

            <HelperHamburger class="visible xl:invisible" />
        </nav>
    </header>
</template>

<style>
header .page-title {
    margin-top: 5px;
}
</style>

<script>
import HelperHamburger from "./HelperHamburger";
import NavigationSub from "./NavigationSub";

export default {
    name: "MainHeader",
    computed: {
        homeLink: function() {
            if (this.$route.path.startsWith("/content")) {
                return "/content/home";
            }
            return "/";
        }
    },
    components: {
        HelperHamburger,
        NavigationSub
    }
};
</script>
