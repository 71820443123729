<template>
    <TileContainer>
        <router-link to="/content/my-profile">
            <Tile overlayFull="true" overlayColor="beigene-blue">
                <template #icon><IconUserS /></template>
                <template #label>{{ $t("home.myProfile") }}</template>
            </Tile>
        </router-link>
        <router-link to="/content/agenda" v-if="agendaEnabled">
            <Tile overlayFull="true" overlayColor="beigene-teal">
                <template #icon><IconAgenda /></template>
                <template #label>{{ $t("home.agenda") }}</template>
            </Tile>
        </router-link>
        <router-link to="/content/resources" v-if="resourcesEnabled">
            <Tile overlayFull="true" overlayColor="beigene-blue">
                <template #icon><IconFolderS /></template>
                <template #label>{{ $t("home.resources") }}</template>
            </Tile>
        </router-link>
    </TileContainer>
</template>

<script>
import TileContainer from "../../components/TileContainer";
import Tile from "../../components/Tile";
import IconStatusOnlineS from "../../components/icons/StatusOnlineS";
import IconUserS from "../../components/icons/UserS";
import IconViewGridS from "../../components/icons/ViewGridS";
import IconFolderS from "../../components/icons/FolderS";
import IconAgenda from "../../components/icons/Agenda";

export default {
    name: "Home2D",
    components: {
        TileContainer,
        Tile,
        IconStatusOnlineS,
        IconUserS,
        IconViewGridS,
        IconFolderS,
        IconAgenda
    },
    data() {
        return {
            active: true,
            resourcesEnabled:
                process.env.VUE_APP_RESOURCES_ENABLED === "true" ? true : false,
            agendaEnabled:
                process.env.VUE_APP_AGENDA_ENABLED === "true" ? true : false
        };
    }
};
</script>
