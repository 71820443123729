<template>
    <div class="grid grid-cols-24">
        <div class="col-span-24 mb-6">
            <div
                class="col-span-24 text-beigene-grey text-2xl text-center mb-2 mt-6"
            >
                <p
                    v-if="success"
                    v-html="$t('messages.cancelParticipationSuccess')"
                ></p>
                <p v-if="!success" v-html="$t('messages.loading')"></p>
                <t-button
                    v-on:click="register"
                    type="button"
                    class="inline rounded-full w-48 h-12 text-xl font-semibold mt-10"
                    >{{ $t("basics.register") }}</t-button
                >
                <transition name="fade">
                    <p
                        v-if="errorMessage"
                        class="absolute h-4 text-red-700 font-semibold text-left col-start-2 col-span-3 mt-5"
                    >
                        {{ errorMessage }}
                    </p>
                </transition>
            </div>
        </div>
    </div>
</template>
<style></style>
<script>
import store from "../store/store";
import router from "../router";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

const url = process.env.VUE_APP_API_URL;

export default {
    name: "CancelParticipation",
    data: function() {
        return {
            errorMessage: "",
            errors: "",
            success: false
        };
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    mounted() {
        this.cancelParticipation().then(success => {
            if (success) {
                setTimeout(() => {
                    router.push("/");
                }, 8000);
            }
        });
    },
    methods: {
        register() {
            router.push("/register");
        },
        async cancelParticipation() {
            try {
                const result = await this.$api.remove.cancelParticipation();
                this.success = true;
                store.commit("clearAccessCancelParticipation");
                return true;
            } catch (error) {
                this.errorMessage = this.$i18n.t(
                    "messagesError.somethingWrong"
                );
                if (
                    typeof error === "undefined" ||
                    typeof error.response === "undefined" ||
                    typeof error.response.data === "undefined" ||
                    typeof error.response.data.code === "undefined"
                ) {
                    return false;
                }

                const code = error.response.data.code;

                switch (code) {
                    case "authentication_required":
                        router.push("/login");
                        break;
                    case "data_conflict":
                        this.errorMessage = this.$i18n.t(
                            "messagesError.somethingWrong"
                        );
                        break;
                    default:
                        break;
                }
                return false;
            }
        }
    }
};
</script>
