<template>
    <router-link
        :to="link"
        active-class="text-white bg-beigene-blue"
        class="flex w-full py-1.5 px-4 rounded-xl items-center space-x-2 text-md hover:text-white hover:bg-beigene-light-blue"
        ><div class="flex w-6">
            <slot><IconEmpty /></slot>
        </div>
        <div class="font-semibold">{{ label }}&nbsp;</div>
    </router-link>
</template>

<script>
import IconEmpty from "./icons/Empty";

export default {
    name: "NavigationMainItemRouterLink",
    props: ["label", "link"],
    components: {
        IconEmpty
    }
};
</script>
