<template>
    <div class="flex max-w-screen-xl w-full layerBG">
        <div class="w-full my-4 mx-4 2xl:mx-0">
            <transition name="fade">
                <div
                    class="container flex flex-col items-center justify-center"
                >
                    <div
                        class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
                        role="status"
                        v-if="loadingLimits"
                    >
                        <span
                            class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="size-6"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </transition>
            <transition name="fade">
                <div
                    class="container mx-auto text-center overflow-y-auto"
                    v-if="regEnabled && !loadingLimits"
                >
                    <h1
                        class="text-beigene-blue font-bold mb-7"
                        v-html="
                            $t(
                                !limits.registrationLimitReached
                                    ? 'messages.welcome'
                                    : 'messagesError.registrationLimitReached'
                            )
                        "
                    ></h1>

                    <div class="mb-12 text-2xl mx-auto messages-title">
                        <p v-html="$t('messages.title')"></p>
                        <p
                            class="mt-2 text-xl"
                            v-html="
                                $t('basics.viewLocation', {
                                    link:
                                        '<a target=\'_blank\' href=\'https://maps.app.goo.gl/cnrbaqS1NUpzeDmw8\' class=\'text-beigene-blue\'>' +
                                        $t('basics.viewLocationLabel') +
                                        '</a>',
                                })
                            "
                        ></p>
                    </div>

                    <div
                        class="flex flex-col justify-center"
                        :class="{
                            'mb-14': loginEnabled,
                            'mb-12': !loginEnabled,
                        }"
                    >
                        <div
                            class="flex flex-row w-full justify-center space-x-4"
                            :class="{
                                'mt-4': loginEnabled,
                                'mt-4': !loginEnabled,
                            }"
                            v-if="limits"
                        >
                            <t-button
                                v-on:click="register"
                                type="button"
                                class="inline rounded-full w-48 h-12 text-xl font-semibold"
                                :class="{
                                    'mb-2': loginEnabled,
                                    'bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed':
                                        loadingLimits,
                                }"
                                v-if="!limits.registrationLimitReached"
                                >{{
                                    !loadingLimits
                                        ? $t("basics.register")
                                        : $t("basics.loading")
                                }}</t-button
                            >

                            <p
                                class="h-12 items-center text-2xl flex xl:hidden"
                                v-if="
                                    loginEnabled &&
                                    !limits.registrationLimitReached
                                "
                            >
                                or
                            </p>

                            <t-button
                                v-on:click="login"
                                type="button"
                                class="inline rounded-full w-48 h-12 text-xl font-semibold xl:hidden"
                                v-if="loginEnabled"
                                >{{ $t("basics.login") }}</t-button
                            >
                        </div>
                    </div>
                    <AgendaContent class="mb-8 text-left bg-white p-4" />
                    <p
                        class="text-2xl"
                        v-html="
                            $t('basics.issues', {
                                link:
                                    '<a target=\'_blank\' title=\'support@infill.com\' href=\'mailto:support@infill.com?subject=' +
                                    encodeURIComponent(
                                        $t('basics.issuesMailSubject')
                                    ) +
                                    '\' class=\'text-beigene-blue\'>' +
                                    $t('basics.issuesLabel') +
                                    '</a>',
                            })
                        "
                    ></p>
                </div>
            </transition>
        </div>
    </div>
</template>
<style>
main form .container .messages-title {
    max-width: 50rem;
}
</style>
<script>
import router from "../router";
import AgendaContent from "./content/AgendaContent";

const url = process.env.VUE_APP_API_URL;

export default {
    components: { AgendaContent },
    data() {
        return {
            errorMessage: "",
            errors: "",
            loginEnabled:
                process.env.VUE_APP_LOGIN_ENABLED === "true" ? true : false,
            regEnabled:
                process.env.VUE_APP_REGISTRATION_ENABLED === "true"
                    ? true
                    : false,
            loadingLimits: true,
            limits: {},
        };
    },
    methods: {
        login() {
            router.push("/login");
        },
        register() {
            if (!this.limits.registrationLimitReached) {
                router.push("/register");
            }
        },
        async getRegistrationLimits() {
            try {
                const limits = await this.$api.registration.limits.beigene();
                if (limits) {
                    this.limits = limits;
                    this.loadingLimits = false;
                }
            } catch (e) {
                console.error(e);

                // Retry every 5 seconds
                setTimeout(() => {
                    this.getRegistrationLimits();
                }, 5000);
            }
        },
    },
    beforeMount() {
        this.getRegistrationLimits();
    },
};
</script>
