<template>
    <div
        v-on:click="takeAction()"
        target="_blank"
        class="flex w-full py-1.5 px-4 rounded-xl items-center space-x-2 text-md hover:text-white hover:bg-beigene-light-blue cursor-pointer"
    >
        <div class="flex w-6">
            <slot><IconEmpty /></slot>
        </div>
        <div class="font-semibold">{{ label }}&nbsp;</div>
    </div>
</template>

<script>
import IconEmpty from "./icons/Empty";

export default {
    name: "NavigationMainItemActionLink",
    props: ["label", "link"],
    components: {
        IconEmpty
    },
    methods: {
        takeAction() {
            switch (this.link) {
                case "openCometChat":
                    if (
                        typeof CometChatWidget !== "undefined" &&
                        typeof CometChatWidget.openOrCloseChat === "function"
                    ) {
                        CometChatWidget.openOrCloseChat(true);
                    }
                    break;

                default:
                    break;
            }
        }
    }
};
</script>
