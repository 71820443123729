<template>
    <div class="w-full m-4 mb-0 md:m-12 md:mt-11 md:mb-0 relative">
        <TileContainer>
            <a
                v-bind:href="'tel:' + $t('contactus.number')"
                v-bind:title="$t('contactus.numberSpaces')"
            >
                <Tile overlayFull="true" overlayColor="beigene-light-blue">
                    <template #icon><IconPhone /></template>
                    <template #label>{{
                        $t("contactus.numberSpaces")
                    }}</template></Tile
                >
            </a>
            <a
                v-bind:href="
                    'mailto:support@infill.com?subject=' +
                        encodeURIComponent($t('basics.issuesMailSubject'))
                "
                title="support@infill.com"
            >
                <Tile overlayFull="true" overlayColor="beigene-teal">
                    <template #icon><IconMail /></template>
                    <template #label>{{
                        $t("contactus.button")
                    }}</template></Tile
                >
            </a>
        </TileContainer>
    </div>
</template>
<script>
import TileContainer from "../../components/TileContainer";
import Tile from "../../components/Tile";
import IconPhone from "../../components/icons/PhoneS";
import IconMail from "../../components/icons/MailS";

export default {
    name: "Resources",
    components: {
        TileContainer,
        Tile,
        IconPhone,
        IconMail
    }
};
</script>
