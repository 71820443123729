var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex max-w-screen-xl w-full layerBG"},[_c('div',{staticClass:"w-full my-4 mx-4 2xl:mx-0"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"container flex flex-col items-center justify-center"},[(_vm.loadingLimits)?_c('div',{staticClass:"inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white",attrs:{"role":"status"}},[_c('span',{staticClass:"!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"},[_c('svg',{staticClass:"size-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"}})])])]):_vm._e()])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.regEnabled && !_vm.loadingLimits)?_c('div',{staticClass:"container mx-auto text-center overflow-y-auto"},[_c('h1',{staticClass:"text-beigene-blue font-bold mb-7",domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                            !_vm.limits.registrationLimitReached
                                ? 'messages.welcome'
                                : 'messagesError.registrationLimitReached'
                        )
                    )}}),_c('div',{staticClass:"mb-12 text-2xl mx-auto messages-title"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('messages.title'))}}),_c('p',{staticClass:"mt-2 text-xl",domProps:{"innerHTML":_vm._s(
                            _vm.$t('basics.viewLocation', {
                                link:
                                    '<a target=\'_blank\' href=\'https://maps.app.goo.gl/cnrbaqS1NUpzeDmw8\' class=\'text-beigene-blue\'>' +
                                    _vm.$t('basics.viewLocationLabel') +
                                    '</a>',
                            })
                        )}})]),_c('div',{staticClass:"flex flex-col justify-center",class:{
                        'mb-14': _vm.loginEnabled,
                        'mb-12': !_vm.loginEnabled,
                    }},[(_vm.limits)?_c('div',{staticClass:"flex flex-row w-full justify-center space-x-4",class:{
                            'mt-4': _vm.loginEnabled,
                            'mt-4': !_vm.loginEnabled,
                        }},[(!_vm.limits.registrationLimitReached)?_c('t-button',{staticClass:"inline rounded-full w-48 h-12 text-xl font-semibold",class:{
                                'mb-2': _vm.loginEnabled,
                                'bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed':
                                    _vm.loadingLimits,
                            },attrs:{"type":"button"},on:{"click":_vm.register}},[_vm._v(_vm._s(!_vm.loadingLimits ? _vm.$t("basics.register") : _vm.$t("basics.loading")))]):_vm._e(),(
                                _vm.loginEnabled &&
                                !_vm.limits.registrationLimitReached
                            )?_c('p',{staticClass:"h-12 items-center text-2xl flex xl:hidden"},[_vm._v(" or ")]):_vm._e(),(_vm.loginEnabled)?_c('t-button',{staticClass:"inline rounded-full w-48 h-12 text-xl font-semibold xl:hidden",attrs:{"type":"button"},on:{"click":_vm.login}},[_vm._v(_vm._s(_vm.$t("basics.login")))]):_vm._e()],1):_vm._e()]),_c('AgendaContent',{staticClass:"mb-8 text-left bg-white p-4"}),_c('p',{staticClass:"text-2xl",domProps:{"innerHTML":_vm._s(
                        _vm.$t('basics.issues', {
                            link:
                                '<a target=\'_blank\' title=\'support@infill.com\' href=\'mailto:support@infill.com?subject=' +
                                encodeURIComponent(
                                    _vm.$t('basics.issuesMailSubject')
                                ) +
                                '\' class=\'text-beigene-blue\'>' +
                                _vm.$t('basics.issuesLabel') +
                                '</a>',
                        })
                    )}})],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }