<template>
    <li class="py-1">
        <NavigationMainItemRouterLink v-if="link" :label="label" :link="link"
            ><slot
        /></NavigationMainItemRouterLink>
        <NavigationMainItemExternalLink
            v-if="externalLink"
            :eventControl="eventControl"
            :label="label"
            :link="externalLink"
            ><slot
        /></NavigationMainItemExternalLink>
        <NavigationMainItemActionLink
            v-if="actionLink"
            :label="label"
            :link="actionLink"
            ><slot
        /></NavigationMainItemActionLink>
    </li>
</template>

<script>
import NavigationMainItemRouterLink from "./NavigationMainItemRouterLink";
import NavigationMainItemExternalLink from "./NavigationMainItemExternalLink";
import NavigationMainItemActionLink from "./NavigationMainItemActionLink";

/* eventControlled is only available for external links */
export default {
    name: "NavigationMainItem",
    props: ["label", "link", "externalLink", "actionLink", "eventControl"],
    components: {
        NavigationMainItemRouterLink,
        NavigationMainItemExternalLink,
        NavigationMainItemActionLink
    }
};
</script>
