<template>
    <div
        class="w-full m-4 mb-0 md:m-12 md:mt-11 md:mb-0 relative bg-white shadow-lg p-4 max-w-1032"
    >
        <div class="flex items-center space-x-3.5 mb-4 pb-4 border-b">
            <div
                class="flex-none w-12 h-12 rounded-full text-white bg-beigene-blue flex items-center justify-center"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                </svg>
            </div>
            <div>
                <p class="text-2xl font-semibold">
                    {{ $t("myprofile.title") }}
                </p>
                <p class="text-md font-semibold">
                    {{ $t("myprofile.subline") }}
                </p>
            </div>
        </div>

        <ValidationObserver
            v-slot="{ handleSubmit }"
            class="flex max-w-screen-xl w-full"
        >
            <form
                @submit.prevent="handleSubmit(updateProfileData)"
                class="w-full my-4 mx-4 2xl:mx-0"
                id="userInputs"
            >
                <!-- First name -->
                <div class="h-auto w-full flex flex-row justify-left relative">
                    <validation-provider
                        name="firstname"
                        rules="required|min:2"
                        v-slot="{ errors }"
                        mode="aggressive"
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-2"
                    >
                        <label
                            for="firstname"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{ $t("basics.nameFirst") }}
                        </label>
                        <t-input
                            type="text"
                            id="firstname"
                            name="firstname"
                            class="px-3 py-2 rounded-full h-8 col-start-2 col-span-2"
                            v-model="firstname"
                        />
                        <div
                            class="relative h-4 text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1 col-start-2 col-span-3"
                        >
                            {{
                                errors[0]
                                    ? errors[0].replace(
                                          "firstname",
                                          "first name"
                                      )
                                    : ""
                            }}
                        </div>
                    </validation-provider>
                </div>

                <!-- Last name -->
                <div class="h-auto w-full flex flex-row justify-left relative">
                    <validation-provider
                        name="lastname"
                        rules="required|min:2"
                        v-slot="{ errors }"
                        mode="aggressive"
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-2"
                    >
                        <label
                            for="lastname"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{ $t("basics.nameLast") }}
                        </label>
                        <t-input
                            type="text"
                            id="lastname"
                            name="lastname"
                            class="px-3 py-2 rounded-full h-8 col-start-2 col-span-2"
                            v-model="lastname"
                        />
                        <div
                            class="relative h-4 text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1 col-start-2 col-span-3"
                        >
                            {{
                                errors[0]
                                    ? errors[0].replace("lastname", "last name")
                                    : ""
                            }}
                        </div>
                    </validation-provider>
                </div>

                <!-- E-Mail -->
                <div class="h-auto w-full flex flex-row justify-left relative">
                    <validation-provider
                        name="email"
                        rules="required|min:3|email"
                        v-slot="{ errors }"
                        mode="aggressive"
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-22"
                    >
                        <label
                            for="email"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{ $t("basics.email") }}
                        </label>
                        <t-input
                            type="email"
                            id="email"
                            name="email"
                            class="px-3 py-2 rounded-full h-8 col-start-2 col-span-2"
                            v-model="email"
                        />
                        <div
                            class="relative h-4 text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1 col-start-2 col-span-3"
                        >
                            {{
                                errors[0]
                                    ? errors[0].replace("email", "e-mail")
                                    : ""
                            }}
                        </div>
                    </validation-provider>
                </div>

                <!-- Institution -->
                <div class="h-auto w-full flex flex-row justify-left relative">
                    <validation-provider
                        name="institution"
                        rules="required"
                        v-slot="{ errors }"
                        mode="aggressive"
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-2"
                    >
                        <label
                            for="institution"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{ $t("basics.institution") }}</label
                        >
                        <t-input
                            type="text"
                            id="institution"
                            name="institution"
                            class="px-3 py-2 rounded-full h-8 col-start-2 col-span-2"
                            v-model="institution"
                        />
                        <div
                            class="relative text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1 col-start-2 col-span-3"
                        >
                            {{ errors[0] }}
                        </div>
                    </validation-provider>
                </div>

                <!-- BeiGene Contact -->
                <div class="h-auto w-full flex flex-row justify-left relative">
                    <validation-provider
                        name="beigenecontact"
                        rules="min:0|alpha_spaces"
                        v-slot="{ errors }"
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-2"
                    >
                        <label
                            for="institution"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{ $t("basics.beigenecontact") }}</label
                        >
                        <t-input
                            type="text"
                            id="beigenecontact"
                            name="beigenecontact"
                            class="px-3 py-2 rounded-full h-8 col-start-2 col-span-2"
                            v-model="beigenecontact"
                        />
                        <div
                            class="relative text-red-700 font-semibold text-base sm:text-xs mx-0 mt-1 col-start-2 col-span-3"
                        >
                            {{
                                errors[0]
                                    ? errors[0].replace(
                                          "beigenecontact",
                                          "BeiGene Contact"
                                      )
                                    : ""
                            }}
                        </div>
                    </validation-provider>
                </div>

                <!-- Dietary restrictions -->
                <div
                    class="h-auto w-full flex flex-row justify-left relative border-b mb-6"
                >
                    <validation-provider
                        name="dietaryrestrictions"
                        rules="min:0"
                        v-slot="{ errors }"
                        mode="aggressive"
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-2"
                    >
                        <label
                            for="dietaryrestrictions"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{ $t("basics.dietaryRestrictions") }}
                        </label>
                        <t-input
                            type="text"
                            id="dietaryrestrictions"
                            name="dietaryrestrictions"
                            class="px-3 py-2 rounded-full h-8 col-start-2 col-span-2"
                            v-model="dietaryrestrictions"
                        />
                        <div
                            class="relative h-4 text-red-700 font-semibold text-base sm:text-xs mx-0 mt-1 col-start-2 col-span-3"
                        >
                            {{
                                errors[0]
                                    ? errors[0].replace(
                                          "dietaryrestrictions",
                                          "Dietary restrictions"
                                      )
                                    : ""
                            }}
                        </div>
                    </validation-provider>
                </div>
                <!-- / Dietary restrictions -->

                <!-- Shuttle service Ifema to Hotel-->
                <div
                    class="h-auto w-full flex flex-row justify-left relative pb-6"
                >
                    <div
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-1"
                    >
                        <label
                            for="shuttleServiceIfemaHotel"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{
                                $t("basics.shuttleServiceIfemaHotelProfile")
                            }}</label
                        >
                        <span
                            class="flex justify-left self-center rounded-full h-8 col-start-2 col-span-2"
                        >
                            <t-radio
                                type="radio"
                                id="shuttleServiceIfemaHotel-yes"
                                name="shuttleServiceIfemaHotel"
                                value="true"
                                class="m-1 ml-0"
                                v-model="shuttleServiceIfemaHotel"
                            />
                            <label for="yes" class="w-auto m-1">{{
                                $t("basics.yes")
                            }}</label>
                            <t-radio
                                type="radio"
                                id="shuttleServiceIfemaHotel-no"
                                name="shuttleServiceIfemaHotel"
                                value="false"
                                class="m-1 ml-4"
                                v-model="shuttleServiceIfemaHotel"
                            />
                            <label for="no" class="w-auto m-1">{{
                                $t("basics.no")
                            }}</label>
                        </span>
                    </div>
                </div>

                <!-- Shuttle service Ifema to Hotel-->
                <div
                    class="h-auto w-full flex flex-row justify-left relative border-b pb-6 mb-6"
                >
                    <div
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-1"
                    >
                        <label
                            for="shuttleServiceHotelCity"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{
                                $t("basics.shuttleServiceHotelCityProfile")
                            }}</label
                        >
                        <span
                            class="flex justify-left self-center rounded-full h-8 col-start-2 col-span-2"
                        >
                            <t-radio
                                type="radio"
                                id="shuttleServiceHotelCity-yes"
                                name="shuttleServiceHotelCity"
                                value="true"
                                class="m-1 ml-0"
                                v-model="shuttleServiceHotelCity"
                            />
                            <label for="yes" class="w-auto m-1">{{
                                $t("basics.yes")
                            }}</label>
                            <t-radio
                                type="radio"
                                id="shuttleServiceHotelCity-no"
                                name="shuttleServiceHotelCity"
                                value="false"
                                class="m-1 ml-4"
                                v-model="shuttleServiceHotelCity"
                            />
                            <label for="no" class="w-auto m-1">{{
                                $t("basics.no")
                            }}</label>
                        </span>
                    </div>
                </div>

                <!-- Password -->
                <div class="h-auto w-full flex flex-row justify-left relative">
                    <validation-provider
                        name="password"
                        rules="min:3"
                        v-slot="{ errors }"
                        mode="aggressive"
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-2"
                    >
                        <label
                            for="password"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{ $t("basics.password") }}
                        </label>
                        <t-input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="********"
                            class="px-3 py-2 rounded-full h-8 col-start-2 col-span-2"
                            v-model="password"
                        />
                        <div
                            class="relative h-4 text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1 col-start-2 col-span-3"
                        >
                            {{ errors[0] }}
                        </div>
                    </validation-provider>
                </div>

                <!-- Retype Password -->
                <div class="h-auto w-full flex flex-row justify-left relative">
                    <validation-provider
                        name="retype_password"
                        rules="confirmed:password"
                        v-slot="{ errors }"
                        mode="aggressive"
                        class="flex max-w-screen-xl grid grid-cols-3 md:grid-cols-4 grid-rows-2"
                    >
                        <label
                            for="retype_password"
                            class="flex justify-left items-center text-md inline-block w-52"
                            >{{ $t("basics.passwordRetype") }}</label
                        >
                        <t-input
                            type="password"
                            id="retype_password"
                            name="retype_password"
                            placeholder="********"
                            class="px-3 py-2 rounded-full h-8 col-start-2 col-span-2"
                            v-model="retypepassword"
                        />
                        <div
                            class="relative h-4 text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1 col-start-2 col-span-3"
                        >
                            {{
                                errors[0]
                                    ? errors[0].replace(
                                          "retype_password field confirmation",
                                          "password confirmation"
                                      )
                                    : ""
                            }}
                        </div>

                        <t-button
                            v-if="
                                loadingStatus == false &&
                                    updateInProgress == false &&
                                    userChangedData == false
                            "
                            class="inline rounded-xl w-56 h-12 text-md font-semibold col-start-2 col-span-2 bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed mb-12"
                            >{{ $t("myprofile.button") }}</t-button
                        >

                        <t-button
                            v-else-if="
                                loadingStatus == false &&
                                    updateInProgress == false &&
                                    userChangedData == true
                            "
                            type="submit"
                            class="inline rounded-xl w-56 h-12 text-md font-semibold col-start-2 col-span-2 mb-12"
                            >{{ $t("myprofile.button") }}</t-button
                        >

                        <t-button
                            v-else-if="
                                loadingStatus == false &&
                                    updateInProgress == true
                            "
                            class="inline rounded-xl w-56 h-12 text-md font-semibold col-start-2 col-span-2 bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed mb-12"
                            >{{ $t("myprofile.buttonUpdate") }}</t-button
                        >

                        <t-button
                            v-else
                            class="inline rounded-xl w-52 h-12 text-md font-semibold col-start-2 col-span-2 bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed mb-12"
                            >{{ $t("myprofile.buttonLoading") }}</t-button
                        >

                        <transition name="fade">
                            <p
                                v-if="updateSuccessful == true"
                                class="absolute mt-36 h-4 text-green-700 font-semibold text-left col-start-2 col-span-3 mt-5"
                            >
                                {{ $t("messages.messageUpdateSuccess") }}
                            </p>
                        </transition>

                        <transition name="fade">
                            <p
                                v-if="errorMessage"
                                class="absolute mt-36 h-4 text-red-700 font-semibold text-left col-start-2 col-span-3 mt-5"
                            >
                                {{ errorMessage }}
                            </p>
                        </transition>
                    </validation-provider>
                </div>
            </form>
        </ValidationObserver>
        <div
            class="h-auto w-full flex flex-row justify-left relative border-t mb-8"
        >
            <t-button
                v-on:click="cancelParticipationModal"
                class="inline rounded-xl ml-52 xl:ml-56 w-56 h-16 mt-16 text-md font-semibold col-start-2 col-span-2 mb-12"
                >{{ $t("myprofile.cancelPart") }}</t-button
            >
        </div>
        <div v-if="showCancelModal === true" id="modal-template">
            <transition name="modal">
                <div class="modal-mask" v-on:click="cancelModal">
                    <div class="modal-wrapper">
                        <div class="modal-container rounded-xl">
                            <div class="modal-header">
                                <slot name="header">
                                    <b class="text-red-800">
                                        {{ $t("myprofile.cancelPart") }}
                                    </b>
                                </slot>
                            </div>

                            <div class="modal-body">
                                <slot name="body">
                                    {{ $t("myprofile.cancelPartModalBody") }}
                                </slot>
                            </div>

                            <div
                                class="modal-footer w-full flex flex-row justify-end mt-8"
                            >
                                <slot name="footer">
                                    <button
                                        class="inline rounded-full w-20 h-10 text-md font-semibold bg-beigene-grey hover:bg-gray-700 text-white mr-2"
                                        v-on:click="cancelModal"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        class="inline rounded-full w-20 h-10 text-md font-semibold  bg-beigene-blue hover:bg-red-800 text-white"
                                        v-on:click="cancelParticipation"
                                    >
                                        Yes
                                    </button>
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* modal styling */
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
<script>
import store from "../../store/store";
import router from "../../router";
import axios from "axios";
import qs from "qs";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

const url = process.env.VUE_APP_API_URL;

export default {
    name: "MyProfile",
    data: function() {
        return {
            errorMessage: "",
            errors: "",
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            retypepassword: "",
            institution: "",
            version: "",
            misc: "",
            loadingStatus: "",
            updateInProgress: "",
            userChangedData: false,
            updateSuccessful: false,
            beigenecontact: "",
            dietaryrestrictions: "",
            shuttleServiceIfemaHotel: false,
            shuttleServiceHotelCity: false,
            showCancelModal: false
        };
    },
    watch: {
        firstname: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        lastname: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        email: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        password: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        retypepassword: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        institution: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        beigenecontact: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        dietaryrestrictions: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        shuttleServiceIfemaHotel: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        shuttleServiceHotelCity: function(val, oldVal) {
            if (oldVal == "") {
                this.userChangedData = false;
            } else {
                this.userChangedData = true;
                this.updateSuccessful = false;
            }
        },
        updateSuccessful: function() {
            setTimeout(() => {
                this.updateSuccessful = false;
            }, 5000);
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    async beforeMount() {
        // loading
        this.loadingStatus = true;
        // console.log("Loading: ", this.loadingStatus);

        const profile = await this.$api.profile.getProfile();

        this.firstname = profile.firstname;
        this.lastname = profile.lastname;
        this.email = profile.email;
        this.misc = profile.misc;
        this.institution = profile.misc.institution;
        this.beigenecontact = profile.misc.beigeneContact;
        this.dietaryrestrictions = profile.misc.dietaryRestrictions;
        this.shuttleServiceIfemaHotel = profile.misc.shuttleServiceIfemaHotel;
        this.shuttleServiceHotelCity = profile.misc.shuttleServiceHotelCity;
        this.version = profile.version;

        // loading
        if (this.firstname != "") {
            this.loadingStatus = false;
            // console.log("Loading: ", this.loadingStatus);
        }
    },
    methods: {
        cancelParticipationModal() {
            this.showCancelModal = true;
        },
        cancelModal() {
            this.showCancelModal = false;
        },
        cancelParticipation() {
            router.push("/cancelparticipation");
        },
        async updateProfileData() {
            if (this.userChangedData == true) {
                // loading
                this.loadingStatus = false;
                // console.log("Loading while update: ", this.loadingStatus);
                this.updateInProgress = true;
                // console.log("API update call: ", this.updateInProgress);

                const userProfileData = {};
                userProfileData.firstname = this.firstname;
                userProfileData.lastname = this.lastname;
                userProfileData.email = this.email;
                userProfileData.misc = {
                    ...this.misc
                };
                userProfileData.misc.institution = this.institution;
                userProfileData.misc.beigeneContact = this.beigenecontact;
                userProfileData.misc.dietaryRestrictions = this.dietaryrestrictions;
                userProfileData.misc.shuttleServiceIfemaHotel = this.shuttleServiceIfemaHotel;
                userProfileData.misc.shuttleServiceHotelCity = this.shuttleServiceHotelCity;

                userProfileData.misc = {
                    ...userProfileData.misc
                };

                userProfileData.version = this.version;

                if (this.password != "" && this.retypepassword != "") {
                    userProfileData.password = this.password;
                    userProfileData.passwordConfirm = this.retypepassword;
                }

                try {
                    const result = await this.$api.profile.updateProfile(
                        userProfileData
                    );
                    this.version = result.version;
                    // loading
                    this.loadingStatus = false;
                    // console.log("Loading while update: ", this.loadingStatus);
                    this.updateInProgress = false;
                    // console.log("API update call: ", this.updateInProgress);
                    this.userChangedData = false;
                    this.updateSuccessful = true;
                } catch (error) {
                    this.errorMessage = this.$i18n.t(
                        "messagesError.somethingWrong"
                    );

                    if (
                        typeof error === "undefined" ||
                        typeof error.response === "undefined" ||
                        typeof error.response.data === "undefined" ||
                        typeof error.response.data.code === "undefined"
                    ) {
                        return;
                    }

                    const code = error.response.data.code;

                    switch (code) {
                        case "authentication_required":
                            router.push("/login");
                            break;
                        case "data_conflict":
                            this.errorMessage = this.$i18n.t(
                                "messagesError.somethingWrong"
                            );
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }
};
</script>
