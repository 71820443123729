import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import LegalNotice from "../views/LegalNotice.vue";
import RegisterYes from "../views/RegisterYes.vue";
import Login from "../views/Login.vue";
import PasswordForgot from "../views/PasswordForgot";
import PasswordReset from "../views/PasswordReset";
import ContentLayout from "../views/content/Layout";
import ContentHome from "../views/content/Home";
import ContentResources from "../views/content/Resources";
import ContentContactUs from "../views/content/ContactUs";
import MyProfile from "../views/content/MyProfile";
import store from "../store/store";
import checkTokenExpired from "../middlewares/checkTokenExpired";
import checkTokenAuth from "../middlewares/checkTokenAuth";
import processLogout from "../middlewares/processLogout";
import CancelParticipation from "../views/CancelParticipation";
import ContentAgenda from "../views/content/Agenda";

Vue.use(VueRouter);

const regEnabled =
    process.env.VUE_APP_REGISTRATION_ENABLED === "true" ? true : false;

const resourcesEnabled =
    process.env.VUE_APP_RESOURCES_ENABLED === "true" ? true : false;

const agendaEnabled =
    process.env.VUE_APP_AGENDA_ENABLED === "true" ? true : false;

const routes = [
    // basic route
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/legal-notice",
        name: "LegalNotice",
        component: LegalNotice
    },
    // register route
    {
        path: "/register",
        name: "RegisterYes",
        // protect route
        beforeEnter: (to, from, next) => {
            // check vuex store for session cookie
            // if !exists route to /
            if (regEnabled) {
                next();
            } else {
                next({
                    path: "/"
                });
            }
        },
        component: RegisterYes
    },
    // login route
    {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: (to, from, next) => {
            if (regEnabled) {
                next();
            } else {
                next({ path: "/" });
            }
        }
    },
    // password forgot route
    {
        path: "/forgotpassword",
        name: "PasswordForgot",
        component: PasswordForgot,
        beforeEnter: (to, from, next) => {
            if (regEnabled) {
                next();
            } else {
                next({ path: "/" });
            }
        }
    },
    // password reset route
    {
        path: "/resetpassword/:token",
        name: "PasswordReset",
        // protect route
        beforeEnter: (to, from, next) => {
            // check token.length
            if (to.params.token.length == 36 && regEnabled) {
                next();
            } else {
                next({
                    path: "/"
                });
            }
        },
        component: PasswordReset
    },
    {
        path: "/cancelparticipation",
        name: "CancelParticipationLogout",
        component: CancelParticipation
    },
    {
        path: "/content",
        component: ContentLayout,

        // protect route
        beforeEnter: async (to, from, next) => {
            await checkTokenAuth(next);
        },

        children: [
            {
                path: "home",
                name: "ContentHome",
                component: ContentHome
            },
            {
                path: "legal-notice",
                name: "LegalNotice",
                component: LegalNotice
            },
            {
                path: "resources",
                name: "ContentResources",
                beforeEnter: (to, from, next) => {
                    // if !enabled route to /
                    if (resourcesEnabled) {
                        next();
                    } else {
                        next({
                            path: "/content/home"
                        });
                    }
                },
                component: ContentResources
            },
            {
                path: "agenda",
                name: "ContentAgenda",
                beforeEnter: (to, from, next) => {
                    // if !enabled route to /
                    if (agendaEnabled) {
                        next();
                    } else {
                        next({
                            path: "/content/home"
                        });
                    }
                },
                component: ContentAgenda
            },
            {
                path: "contact-us",
                name: "ContentContactUs",
                component: ContentContactUs
            },
            {
                path: "my-profile",
                name: "ContentMyProfile",
                component: MyProfile
            },
            {
                path: "logout",
                name: "ContentLogout",
                beforeEnter: async (to, from, next) => {
                    await processLogout(next);
                }
            },
            {
                path: "/",
                redirect: "home"
            },
            {
                path: "*",
                redirect: "home"
            }
        ]
    },
    // if route !exists
    {
        path: "*",
        redirect: "/"
    }
];

// new router component
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    // check Auth token exiration date on every request inside "/content"-Route
    if (to.path.startsWith("/content")) {
        await checkTokenExpired(next);
    } else {
        next();
    }
});

export default router;
