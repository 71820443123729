import axios from "axios";
import store from "../store/store";

/* API classes */
export class BaseApiService {
    baseUrl = process.env.VUE_APP_API_URL;
    apiPath = process.env.VUE_APP_API_PATH;
    versionPath = process.env.VUE_APP_API_VERSION;
    resource;

    constructor(resource) {
        if (!resource) throw new Error("Resource is not provided");
        this.resource = resource;
    }

    getUrl(id = "") {
        return `${this.baseUrl}${this.apiPath}${this.versionPath}${this.resource}/${id}`;
    }

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        // console.log({ message: "Errors is handled here", err });
        throw err;
    }

    async request(config = {}) {
        try {
            const defaults = {
                url: this.getUrl(),
                withCredentials: false,
                headers: {}
            };

            if (store.state.accessToken) {
                defaults.headers.Authorization =
                    "Bearer " + store.state.accessToken;

                if (typeof config.headers !== "undefined") {
                    config.headers.Authorization =
                        "Bearer " + store.state.accessToken;
                }
            }

            const response = await axios.request({
                ...defaults,
                ...config
            });

            return response;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async fetch(config = {}) {
        try {
            const response = await this.request(config);

            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export class ReadOnlyApiService extends BaseApiService {
    constructor(resource) {
        super(resource);
    }

    async get(id) {
        try {
            if (!id) throw Error("Id is not provided");
            const response = await this.request({ url: this.getUrl(id) });

            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export class ActionApiService extends ReadOnlyApiService {
    constructor(resource) {
        super(resource);
    }

    async post(action, data = {}) {
        try {
            const response = await this.request({
                url: this.getUrl(action),
                method: "POST",
                body: JSON.stringify(data)
            });

            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export class ModelApiService extends ReadOnlyApiService {
    constructor(resource) {
        super(resource);
    }

    async post(data = {}) {
        try {
            const response = await axios.request({
                url: this.getUrl(),
                method: "POST",
                body: JSON.stringify(data)
            });

            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
    async patch(id, data = {}) {
        if (!id) throw Error("Id is not provided");
        try {
            const response = await axios.request({
                url: this.getUrl(),
                method: "PATCH",
                body: JSON.stringify(data)
            });

            return await response;
        } catch (err) {
            this.handleErrors(err);
        }
    }
    async put(id, data = {}) {
        if (!id) throw Error("Id is not provided");
        try {
            const response = await axios.request({
                url: this.getUrl(),
                method: "PUT",
                body: JSON.stringify(data)
            });

            return await response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
    async delete(id) {
        if (!id) throw Error("Id is not provided");
        try {
            await axios.request({
                url: this.getUrl(),
                method: "DELETE"
            });
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
