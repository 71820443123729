<template>
    <div class="flex max-w-screen-xl w-full layerBG">
        <div class="w-full my-4 mx-4 2xl:mx-0 text-center overflow-y-auto">
            <h1
                class="text-beigene-blue font-bold mb-7"
                v-html="$t('messages.welcome-login')"
            ></h1>
            <div class="mb-12 text-2xl  mx-auto text-center">
                <p v-html="$t('messages.titleRegister')"></p>
                <p
                    class="mt-2 text-xl"
                    v-html="
                        $t('basics.viewLocation', {
                            link:
                                '<a target=\'_blank\' href=\'https://maps.app.goo.gl/cnrbaqS1NUpzeDmw8\' class=\'text-beigene-blue\'>' +
                                $t('basics.viewLocationLabel') +
                                '</a>'
                        })
                    "
                ></p>
            </div>

            <div class="relative">
                <div v-bind:class="{ 'opacity-25': !loginEnabled }">
                    <p class="mb-6 text-2xl">
                        {{ $t("basics.emailLogin") }}
                    </p>
                    <ValidationObserver v-slot="{ handleSubmit }">
                        <form
                            @submit.prevent="handleSubmit(login)"
                            id="userInputs"
                        >
                            <div class="max-w-screen-sm mx-auto">
                                <div
                                    class="grid grid-cols-12 gap-x-4 gap-y-8 mb-10"
                                >
                                    <div
                                        class="md:col-start-1 md:h-11 xl:h-auto col-span-12 sm:col-span-3 text-left sm:text-right"
                                    >
                                        <label
                                            for="email"
                                            class="h-full inline-block flex flex-col justify-center text-2xl"
                                            >{{ $t("basics.email") }}</label
                                        >
                                    </div>

                                    <div
                                        class="md:h-11 xl:h-auto col-span-12 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                                    >
                                        <validation-provider
                                            name="email"
                                            rules="required|min:3|email"
                                            v-slot="{ errors }"
                                            mode="aggressive"
                                        >
                                            <t-input
                                                type="email"
                                                id="email"
                                                name="email"
                                                class="px-3 py-2 rounded-full h-10 w-full"
                                                v-model="email"
                                            />
                                            <div
                                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                                            >
                                                {{
                                                    errors[0]
                                                        ? errors[0].replace(
                                                              "email",
                                                              "e-mail"
                                                          )
                                                        : ""
                                                }}
                                            </div>
                                        </validation-provider>
                                    </div>
                                    <div
                                        class="md:col-start-1 md:h-11 xl:h-auto col-span-11 sm:col-span-3 md:col-span-3 text-left sm:text-right"
                                    >
                                        <label
                                            for="password"
                                            class="h-full inline-block flex flex-col justify-center text-2xl"
                                            >{{ $t("basics.password") }}</label
                                        >
                                    </div>
                                    <div
                                        class="md:h-11 xl:h-auto col-span-12 sm:col-span-8 flex flex-col justify-center relative -mt-6 sm:mt-0"
                                    >
                                        <validation-provider
                                            name="password"
                                            rules="required|min:3"
                                            v-slot="{ errors }"
                                            mode="aggressive"
                                        >
                                            <t-input
                                                type="password"
                                                id="password"
                                                name="password"
                                                class="px-3 py-2 rounded-full h-10 w-full"
                                                v-model="password"
                                            />
                                            <div
                                                class="absolute text-red-700 font-semibold text-base sm:text-xs mx-3 mt-1"
                                            >
                                                {{ errors[0] }}
                                            </div>
                                        </validation-provider>
                                    </div>

                                    <div class="col-span-11 text-right -mt-4">
                                        <p class="text-lg">
                                            <a
                                                href="/forgotpassword"
                                                target="_self"
                                            >
                                                {{ $t("basics.forgot") }}
                                            </a>
                                        </p>
                                    </div>
                                </div>

                                <div
                                    class="col-span-24 text-center text-red-700 font-semibold relative"
                                >
                                    <p
                                        v-if="errorMessage"
                                        class="absolute w-full -mt-8 text-red-700 font-semibold text-center"
                                    >
                                        {{ errorMessage }}
                                    </p>
                                </div>

                                <div
                                    class="col-span-24 w-auto mb-8 px-10 sm:px-28"
                                >
                                    <t-button
                                        v-if="
                                            errors != '' ||
                                                email == '' ||
                                                password == ''
                                        "
                                        class="mb-8 inline rounded-full w-48 h-12 bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed text-xl font-semibold float-right"
                                        >{{ $t("basics.login") }}</t-button
                                    >

                                    <t-button
                                        v-else-if="APIcallInProgress === true"
                                        class="mb-8 inline rounded-full w-48 h-12 bg-beigene-grey hover:bg-beigene-grey cursor-not-allowed text-xl font-semibold float-right"
                                        >{{ $t("basics.loading") }}</t-button
                                    >

                                    <t-button
                                        v-else
                                        type="submit"
                                        class="mb-8 inline rounded-full w-48 h-12 text-xl font-semibold float-right"
                                        >{{ $t("basics.login") }}</t-button
                                    >
                                    <t-button
                                        class="inline border-3 border-beigene-blue rounded-full w-48 h-12 text-beigene-blue bg-opacity-0 shadow-sm rounded hover:bg-beigene-blue hover:text-white hover:border-beigene-light-blue text-xl font-bold"
                                        @click="goBack"
                                        >{{ $t("basics.back") }}</t-button
                                    >
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>

                    <p
                        class="mt-8 text-2xl"
                        v-html="
                            $t('basics.issues', {
                                link:
                                    '<a target=\'_blank\' title=\'support@infill.com\' href=\'mailto:support@infill.com?subject=' +
                                    encodeURIComponent(
                                        $t('basics.issuesMailSubject')
                                    ) +
                                    '\' class=\'text-beigene-blue\'>' +
                                    $t('basics.issuesLabel') +
                                    '</a>'
                            })
                        "
                    ></p>
                </div>
                <!-- Login not available yet -->
                <div
                    class="absolute inset-0 flex flex-col justify-center"
                    v-if="!loginEnabled"
                >
                    <div class="w-full flex flex-row justify-center">
                        <span
                            class="w-96 h-40 bg-beigene-bg-blue font-semibold rounded-lg flex flex-col justify-center text-white whitespace-pre"
                            >{{ $t("basics.loginNotAvailable") }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from "../store/store";
import router from "../router";
import axios from "axios";
import qs from "qs";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

const url = process.env.VUE_APP_API_URL;

export default {
    data() {
        return {
            errorMessage: "",
            errors: "",
            password: "",
            email: store.state.email,
            loginEnabled:
                process.env.VUE_APP_LOGIN_ENABLED === "true" ? true : false,
            APIcallInProgress: false
        };
    },
    components: {
        ValidationObserver,
        ValidationProvider
    },
    methods: {
        goBack() {
            router.push("/");
        },
        login() {
            if (!this.loginEnabled) {
                return;
            }

            if (this.errors == "") {
                // get email from input
                const userInputForm = document.getElementById("userInputs");
                const emailFromUser = userInputForm.querySelector(
                    "input[name=email]"
                );
                const passwordFromUser = userInputForm.querySelector(
                    "input[name=password]"
                );

                const axiosData = qs.stringify({
                    grant_type: "password",
                    username: emailFromUser.value,
                    password: passwordFromUser.value
                });

                this.APIcallInProgress = true;
                // post login request
                axios
                    .post(url + "api/v1/auth/login?o=beigene", axiosData, {
                        headers: {
                            "Content-Type":
                                "application/x-www-form-urlencoded;charset=UTF-8"
                        },
                        withCredentials: false
                    })
                    .then(response => {
                        //console.log("Response: " + JSON.stringify(response));

                        const jwt = response.data.jwt;
                        const accessToken = response.data.accessToken;
                        const accessTokenExpiresAt =
                            response.data.accessTokenExpiresAt;

                        this.$store.commit("updateJWT", jwt);
                        this.$store.commit("emailAdress", response.data.email);

                        this.$store.commit("updateAccessToken", accessToken);
                        this.$store.commit(
                            "updateAccessTokenExpiresAt",
                            accessTokenExpiresAt
                        );

                        this.$api.profile.getProfile().then(profile => {
                            this.$store.commit(
                                "userFirstName",
                                profile.firstname
                            );
                            this.$store.commit(
                                "userLastName",
                                profile.lastname
                            );
                        });

                        router.push("/content/home");
                        this.APIcallInProgress = false;
                    })
                    .catch(error => {
                        this.APIcallInProgress = false;
                        if (
                            typeof error === "undefined" ||
                            typeof error.response === "undefined" ||
                            typeof error.response.data === "undefined" ||
                            typeof error.response.data.code === "undefined" ||
                            typeof error.response.data.status === "undefined"
                        ) {
                            this.errorMessage = this.$i18n.t(
                                "messagesError.somethingWrong"
                            );

                            return;
                        } else {
                            this.errorMessage = this.$i18n.t(
                                "messagesError.somethingWrong"
                            );

                            const status = error.response.data.status;
                            const code = error.response.data.code;

                            switch (status) {
                                case 400:
                                    switch (code) {
                                        case "invalid_grant":
                                            this.errorMessage = this.$i18n.t(
                                                "messagesErrorAPI.invalid_grant"
                                            );
                                            break;
                                        default:
                                            break;
                                    }
                                case 503:
                                    switch (code) {
                                        case "too_much_login_tries":
                                            this.errorMessage = this.$i18n.t(
                                                "messagesErrorAPI.too_much_login_tries"
                                            );
                                            break;
                                        default:
                                            break;
                                    }

                                    break;
                                default:
                                    break;
                            }
                        }
                    });
            } else {
                this.errorMessage = this.$i18n.t(
                    "messagesError.somethingWrong"
                );
            }
        }
    },
    computed: {}
};
</script>
