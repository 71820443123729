import store from "../store/store";

const regEnabled =
    process.env.VUE_APP_REGISTRATION_ENABLED === "true" ? true : false;
const loginEnabled =
    process.env.VUE_APP_LOGIN_ENABLED === "true" ? true : false;

export default async function checkAuth(next) {
    let authenticated = true;

    if (!regEnabled || !loginEnabled) {
        authenticated = false;
    }

    let user = {
        misc: {}
    };

    try {
        user = await store.$api.profile.getProfile();
    } catch (e) {
        authenticated = false;
    }

    if (!authenticated) {
        next({ path: "/login" });
    } else {
        next();
    }
}
